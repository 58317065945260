import React from 'react';
import { Navigate } from 'react-router-dom';
import { store } from './../_helpers/store';
import { canAccess } from '../_helpers';

const PrivateRoute = ({ children, redirectTo }) => {
    const currentState = store.getState()?.login;
    const authGuard = canAccess(currentState);
	return authGuard && localStorage.getItem("user") && localStorage.getItem("access_token") ? children : <Navigate to={redirectTo} />;
}

export default PrivateRoute;