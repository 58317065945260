import { loginConstants } from "../_constants";

export const loginActions = {
    login,
    userLogout,
    logout
};


function login(user){
    return dispatch => {
        dispatch(success({user}));
    }
    function success(user) {
        return {
            type: loginConstants.LOGIN_REQUEST, user
        }
    }
}

function logout() {
    // userService.logout();
    localStorage.clear();
	localStorage.removeItem('user');
    return { type: loginConstants.USER_LOGOUT };
}

function userLogout() {
    return {
        type : loginConstants.USER_LOGOUT
    }
}