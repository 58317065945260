import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import moment from "moment";

const PartnerUsersData = (props) => {

    const data = props?.data;
    const user_details = data.user_details ? data.user_details : "";
    const transaction_count = user_details.transaction_count ? user_details.transaction_count : "";

    return (
        <React.Fragment key={props.key}>
            <TableRow>
                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"0%"}}
                >
                    {data?.id}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1,pr:0, width:"2%"}}
                >
                    {user_details?.id}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1,pr:0, width:"2%"}}
                >
                    {user_details?.partner_id}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, pr:0, width:"2%"}}
                >
                    {user_details.partner_source}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {user_details.country}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {transaction_count?.total}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {data?.status}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {data?.created_at === null ? "--" : moment((data?.created_at)).format("MM-DD-YYYY")}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {user_details?.created_at === null ? "--" : moment((user_details?.created_at)).format("MM-DD-YYYY")}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    --
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default PartnerUsersData