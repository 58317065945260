import { Chip, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { POST_DOWNLOAD_USER_REPORT } from '../../../_services/users';
import { getBody } from '../../../_services';
import { downloadFile } from '../../../_helpers/common-helpers';

const GenerateTaxReportData = (props) => {
    const data = props?.data;

    const setStatusColor = (status) => {
		if(status === "failed") {
			return 'success'
		} else if(status === "Admin") {
			return 'success'
		} else if(status === "User") {
			return 'info'
		}
	}

    const setActionColor = (status) => {
		if(status === "Failed" || status === "Deleted") {
			return 'error'
		}else if(status === "Generating"){
            return 'info'
        }
	}

    const downloadReport = (report_id) => {
        POST_DOWNLOAD_USER_REPORT({tax_report_id: report_id})
        .then((res) => {
            res = getBody(res);
            let pdfURL = res?.data?.reportGetURL
            if(res.code === 200){
                downloadFile(pdfURL);
            }
        })
    };

    return (
        <React.Fragment>
            <TableRow key={props.key}>
                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"6%"}}
                >
                    {data?.datetime}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2,pr:0, width:"2%"}}
                >
                    {data?.email}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2,pr:0, width:"2%"}}
                >
                    {data?.name}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, pr:0, width:"2%"}}
                >
                    {data?.year}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"2%"}}
                >
                    {data?.ip}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"1%"}}
                >
                    <Chip
                        sx={{ p:0, borderRadius:2 }}
                        label={data.requested_by}
                        variant="outlined"
                        color={setStatusColor(data?.requested_by)}
                    ></Chip>
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"1%"}}
                >
                    { (data?.deleted_at != null && data.status === 'Completed') &&
                        <Chip
                            sx={{ p:0, borderRadius:2 }}
                            label={"Deleted"}
                            variant="outlined"
                            color={setActionColor("Deleted")}
                        ></Chip>
                    }
                    { (data?.status === 'Failed') &&
                        <Chip
                            sx={{ p:0, borderRadius:2 }}
                            label={"Failed"}
                            variant="outlined"
                            color={setActionColor("Failed")}
                        ></Chip>
                    }
                    { (data?.status === 'Generating') &&
                        <Chip
                            sx={{ p:0, borderRadius:2 }}
                            label={"Generating"}
                            variant="outlined"
                            color={setActionColor("Generating")}
                        ></Chip>
                    }
                    { (data.deleted_at === null && data.status === "Completed") &&
                        <Tooltip title="Download" arrow>
                            <IconButton onClick={() => downloadReport(data?.id)} sx={{ color:"#9e9e9e" }} size="small">
                                <FileDownloadIcon fontSize="medium"/>
                            </IconButton>
                        </Tooltip>
                    }
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default GenerateTaxReportData