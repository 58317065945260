import React, { useState } from "react";
import {
	Box,
	InputBase,
	ClickAwayListener,
	InputLabel,
	Grid,
	Typography,
	IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { StyledPopper, StyledAutocompletePopper, AutoCompleteViewTxnBtn } from "../CustomDesignComponent";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { userActions } from "../../../_actions";
import { ErrorMessage } from "formik";

const ViewTxnAutocomplete = (props) => {

	const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
    const [txTypeData, setTxTypeData] = useState();
    const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;
    const userTransactionFilter = useSelector((state) => state.userTxnFilter);
	const name = props.name;

    function PopperComponent(props) {
		const { disablePortal, anchorEl, open, ...other } = props;
		return <StyledAutocompletePopper {...other} />;
	}

	PopperComponent.propTypes = {
		anchorEl: PropTypes.any,
		disablePortal: PropTypes.bool,
		open: PropTypes.bool.isRequired,
	};

	const StyledInput = styled(InputBase)(({ theme }) => ({
		width: "100%",
		"& input": {
			display: "none"
		},
	}));

    const handleSelectReason = (select) => {
		if(props.type === "txType"){
			setTxTypeData(select);
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txType: select, page : 1 }))
		}else if(props.type === "txLable"){
			setTxTypeData(select);
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txLable: select, page : 1}))
		}else if(props.type === "txSellCurr"){
			setTxTypeData(select);
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txSellCurr: select, page : 1}))
		}else if(props.type === "txBuyCurr"){
			setTxTypeData(select);
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txBuyCurr: select, page : 1}))
		}else if(props.type === "txExchange"){
			setTxTypeData(select);
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txExchange: select, page : 1}))
		}
		handleClose();
	}

    const handleCategoryButtonClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setAnchorEl(null);
	};

    const handleCloseButton = () => {
		setIsOpen(false);
		setAnchorEl(null);
		if(props.type === "txType"){
			setTxTypeData();
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txType : null, txLable: null, page: 1}))
		}else if(props.type === "txLable"){
			setTxTypeData();
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txLable: null, page: 1}))
		}else if(props.type === "txSellCurr"){
			setTxTypeData();
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txSellCurr: null, page: 1}))
		}else if(props.type === "txBuyCurr"){
			setTxTypeData();
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txBuyCurr: null, page: 1}))
		}else if(props.type === "txExchange"){
			setTxTypeData();
			dispatch(userActions.userTxnFilter({...userTransactionFilter, txExchange: null, page: 1}))
		}
	};

    return (
        <React.Fragment>
            <InputLabel sx={{ fontSize: "12px", color: "text.secondary" }}>
				{props.label}
			</InputLabel>
            <Box position={"relative"}>
				<AutoCompleteViewTxnBtn
					sx={{
						"&.MuiButtonBase-root:hover": {
							backgroundColor: "primary.12p",
						}
					}}
					endIcon={ open ? <ArrowDropUp style={{fontSize: '24px', right:"12px"}} />: <ArrowDropDown style={{fontSize: '24px', right:"12px"}} />}
					onClick={handleCategoryButtonClick}
					fullWidth
					aria-describedby={id}
					variant="standard"
				>
				    {txTypeData ? <Typography sx={{ color: 'text.primary', py: 0.25 }} variant="body2">{txTypeData}</Typography> : <Typography sx={{ color: 'text.secondary', py: 0.25 }} variant="body2">{props.placeholder}</Typography>}
					{ txTypeData && props.isCloseIcon && (
						<IconButton size="small" sx={{ position: "absolute", left:"160px",color: "#57729E" }}  onClick={handleCloseButton}>
							<CloseIcon />
						</IconButton>
					)}
				</AutoCompleteViewTxnBtn>
			</Box>
            {isOpen && (
				<StyledPopper
					id={id}
					open={open}
					anchorEl={anchorEl}
					placement="bottom-start"
					modifiers={[
						{
						  name: "sameWidth",
						  enabled: true,
						  fn: ({ state }) => {
							 state.styles.popper.width = `${state.rects.reference.width}px`;
						  },
						  phase: "beforeWrite",
						  requires: ["computeStyles"],
						  effect: ({ state }) => {
							state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
						  }
						}
					]}
				>
					<ClickAwayListener onClickAway={handleClose}>
						<div>
							<Autocomplete
								open
								options={props.optionData?props.optionData:[]}
								disableCloseOnSelect
								PopperComponent={PopperComponent}
								noOptionsText={"Not Found"}
								getOptionLabel={(option) => option}
								onChange={(event, value) => handleSelectReason(value)}
								value={props.selectedVal?props.selectedVal:null}
								renderOption={(props, option) => (
									<>
										<Grid container justifyContent="left" alignItems="center" wrap="nowrap" sx={{ borderRadius: 0,  cursor: 'pointer', backgroundColor: option === txTypeData?'primary.8p':''}} {...props} key={option}> 
											<Grid item xs={12} sx={{ py: 0.75, position: 'relative' }}>
												{option}
											</Grid>
										</Grid>
									</>
								)}
								sx={{
									display: 'flex'
								}}
								componentsProps={{
									popper: {
									  sx: {
										'& .MuiAutocomplete-listbox' : { py: 1 }
									  }
									}
								}}
								renderInput={(params) => (
									<StyledInput
										ref={params.InputProps.ref}
										inputProps={params.inputProps}
										autoFocus
									/>
								)}
								onClose={(event, reason) => {
									if (reason === "escape") {
										handleClose();
									}
								}}
							/>
						</div>
					</ClickAwayListener>
				</StyledPopper>
			)}

			{!props.isFilter && 
				<ErrorMessage
					component="div"
					name={name}
					style={{ color: 'red', fontSize: "12.5px"}}
				></ErrorMessage>
			}
        </React.Fragment>
    )
}

export default ViewTxnAutocomplete