import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Collapse, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Logo from "../../../assets/images/icon-blue-bg-circular.svg";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
        <ProSidebar collapsed={isCollapsed}>
            <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
                <MenuItem
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                    style={{
                    margin: "10px 0 20px 0",
                    color: colors.grey[100],
                    }}
                >
                    {!isCollapsed && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        ml="15px"
                    >
                        <Typography variant="h3" color={colors.grey[100]}>
                            COINPANDA
                        </Typography>
                        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                            <MenuOutlinedIcon />
                        </IconButton>
                    </Box>
                    )}
                </MenuItem>

                {!isCollapsed && (
                    <Box mb="25px">
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <img
                              alt="profile-user"
                              width="100px"
                              height="100px"
                              src={Logo}
                              style={{ cursor: "pointer", borderRadius: "50%" }}
                            />
                        </Box>
                        <Box textAlign="center">
                            <Typography
                              variant="h2"
                              color={colors.grey[100]}
                              fontWeight="bold"
                              sx={{ m: "10px 0 0 0" }}
                            >
                              Coinpanda
                            </Typography>
                            <Typography variant="h5" color={colors.greenAccent[500]}>
                              coinpanda
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                    <Item
                        title="Dashboard"
                        to="/dashboard"
                        icon={<HomeOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                    />

                    <Typography
                        variant="h6"
                        color={colors.grey[300]}
                        sx={{ m: "15px 0 5px 20px" }}
                    >
                        Main menu
                    </Typography>

                    <MenuItem
                        style={{
                          color: colors.grey[100],
                        }}
                        onClick={handleClick}
                        icon={<PeopleOutlinedIcon />}
                      >
                        <Box display={"flex"} gap={12} >
                            <Typography>User</Typography>
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Box>
                    </MenuItem>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                      {!isCollapsed &&  
                        <Box paddingLeft={4}>
                            <Item
                                title="All Users"
                                to="/users"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Item
                                title="All Accountants"
                                to="/accountants"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Item
                                title="Admin Users"
                                to="/adminUsers"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Item
                                title="Unverified Users"
                                to="/unverified-users"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Item
                                title="Tax Reports"
                                to="/tax-reports"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Item
                                title="Deleted Users"
                                to="/deleted-users"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Item
                                title="Pending Deleted Users"
                                to="/pending-deleted-users"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />

                            <Item
                                title="Partner Users"
                                to="/partner-users"
                                icon={<ArrowRightAltIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </Box>
                      }

                    </Collapse>

                  
                </Box>
            </Menu>
        </ProSidebar>
    </Box>
  );
};

export default Sidebar;
