export const userConstants = {
	ALLUSER_PAGINATION: "ALL_USER_PAGINATION",
	ALLUSER_FILTER: 'ALLUSER_FILTER',
	REMOVE_ALLUSER_FILTER: 'REMOVE_ALLUSER_FILTER',
	EDIT_USER_DETAILS: 'EDIT_USER_DETAILS',
	API_LIMIT_BLOCKCHAIN_DIALOG: "API_LIMIT_BLOCKCHAIN_DIALOG",
	USER_TAX_PLAN_DIALOG: "USER_TAX_PLAN_DIALOG",
	USER_TAX_PLAN_DIALOG: "USER_TAX_PLAN_DIALOG",
	USER_VIEW_TRANSACTION: "USER_VIEW_TRANSACTION",
	USER_VIEW_TXN_FILTER: "USER_VIEW_TXN_FILTER",
	REMOVE_VIEW_TXN_FILTER: "REMOVE_VIEW_TXN_FILTER",
	DOWNLOAD_TAX_FORMS_DIALOG: "DOWNLOAD_TAX_FORMS_DIALOG",
	TAX_REPORT_LIST_FILTER: "TAX_REPORT-LIST",
	REMOVE_TAX_REPORT_LIST_FILTER: "REMOVE_TAX_REPORT_LIST_FILTER",
	DELETE_USER_ACCOUNT: "DELETE_USER_ACCOUNT",



	ALL_ACCOUNTANTS: "ALL_ACCOUNTANTS",
	ADMIN_USERS: "ADMIN_USERS",
	UNVERIFIED_USERS: "UNVERIFIED_USERS",
	TAX_REPORT_TABLE: "TAX_REPORT_TABLE",
	DELETED_USER_TABLE: "DELETED_USER_TABLE",
	DELETED_USER_FILTER: "DELETED_USER_FILTER",
	REMOVE_DELETED_USER_FILTER: "REMOVE_DELETED_USER_FILTER",
	UPDATE_DELETED_USERS: "UPDATE_DELETED_USERS",
	UPDATE_PENDING_DELETED_USERS: "UPDATE_PENDING_DELETED_USERS",
	PARTNER_USERS: "PARTNER_USERS",

};