import React, { useContext } from "react";
import { Box, Menu, MenuItem, IconButton, Tooltip, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { ColorModeContext, tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import { loginActions } from "../../../_actions";

const ProfileSettings = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
	const dispatch = useDispatch();
    let history = useNavigate();
    
    const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

    function logoutUser() {
        dispatch(loginActions.logout())
        history("/login");
	}

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                <Tooltip>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ 
                            p:1,
                            // borderRadius: '4px',
                            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            "&:hover": {
                                background: "rgba(255, 255, 255, 0.08)",
                            }
                        }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <PersonOutlinedIcon htmlColor="#FFFFFF8F"  sx={{ "&:hover": { color: "#FFFFFF" } }}></PersonOutlinedIcon>
                    </IconButton>
                </Tooltip>
            </Box>

            <Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						width:"200px",
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem onClick={logoutUser}>
					Logout
				</MenuItem>
            </Menu>

        </React.Fragment>
    );
}

export default ProfileSettings