import React from 'react';
import { ErrorMessage } from "formik";

function FormErrorMessage(props) {
	const { name } = props;

  	return (
		<ErrorMessage
			component="div"
			name={name}
			style={{ color: 'red', fontSize: "12.5px"}}
		></ErrorMessage>
	)
}

export default FormErrorMessage; 