import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Front from "./front/Front";
import Login from "./front/Components/Login/Login";
import Sidebar from "./front/Components/Sidebar/Sidebar";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./front/Components/Sidebar/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";

export default function RouteComponents() {
	const [theme, colorMode] = useMode();
  	const [isSidebar, setIsSidebar] = useState(true);

	const authentication = useSelector((state) => state.login);

	return (
		<>
			<ColorModeContext.Provider value={colorMode}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
						<div className="app">
							{
								(authentication.loggedIn === true) &&
									<Sidebar isSidebar={isSidebar} />
							}
							<main className="content">
								{
									(authentication.loggedIn === true) &&
									<Topbar setIsSidebar={setIsSidebar} />
								}
								<Routes>
									<Route exact path="*" element={<Front/>} ></Route>
									<Route exact path="/" element={<Login/>} ></Route>
								</Routes>
							</main>
						</div>
				</ThemeProvider>
    		</ColorModeContext.Provider>
		</>
	);
}