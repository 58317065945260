import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    process.env.NODE_ENV === "development"
        ? composeWithDevTools(applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        ))
        : applyMiddleware(thunkMiddleware),
);