import React, { useEffect, useRef, useState } from "react";
import { Divider, Pagination, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { getBody } from "../../../_services";
import {
	Typography,
	Card,
	Skeleton,
	Table,
	TableHead,
	TableRow,
	TableCell,
	CardContent,
	TableBody,
	Stack,
	Grid,
	TableContainer,
    Box
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Spacer from '../Spacer/Spacer';
import { POST_VIEW_USER_TRANSACTION } from "../../../_services/users";
import { useParams } from "react-router-dom";
import ViewTransactionData from "./ViewTransactionData";
import { userActions } from "../../../_actions";
import ViewTxnAutocomplete from "../CustomCode/ViewTxnAutocomplate";

const ViewUserTrasaction = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const transactionType = [ "Receive", "Send","Trade"];
    const dispatch = useDispatch();
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [totalPages, setTotalPage] = useState(0);
    const [viewTransaction, setViewTransaction] = useState({})
    const [labelFilter, setLabelFilter] = useState([])
    const [sellCurrencyFilter, setSellCurrencyFilter] = useState([])
    const [buyCurrencyFilter, setBuyCurrencyFilter] = useState([])
    const [buyWalletFilter, setWalletFilter] = useState([])
    const userTransactionFilter = useSelector((state) => state.userTxnFilter);
    const isUpdate = useSelector((state) => state.updateDeletedUsers);

    const filterOutEmptyData = (filterData) => {
		filterData = Object.fromEntries(
			Object.entries(filterData).filter(([k, v]) => v !== null)
		);
		return filterData;
	};

    useEffect(() => {
		getTransactionRow();
	}, [userTransactionFilter, isUpdate.count]);

    const getTransactionRow = () => {
        let formData = {user_id: params.id, page: userTransactionFilter.page, limit:10, txType: userTransactionFilter.txType,
            txLable: userTransactionFilter.txLable, txSellCurr: userTransactionFilter.txSellCurr, txBuyCurr: userTransactionFilter.txBuyCurr, txExchange: userTransactionFilter.txExchange};

		POST_VIEW_USER_TRANSACTION(filterOutEmptyData(formData))
        .then((res) => {
            res = getBody(res);
            if(res.code === 200){
                dispatch(userActions.userViewTransaction(res?.data?.transactionList));
                setViewTransaction(res?.data?.transactionList);
                setLabelFilter(res?.data?.lablelFilterList);
                setSellCurrencyFilter(res?.data?.sellCoinList);
                setBuyCurrencyFilter(res?.data?.buyCoinFilter);
                setWalletFilter(res?.data?.exxhangeFilterList);
                setTotalPage(Math.ceil(res?.data?.totalCount / userTransactionFilter.per_page))
                setLoading(false);
            }
        })
        .catch((error) =>
            console.log(error)
        );
	};

    const paginationHandle = (value) => {
		if(userTransactionFilter.page !== value){
			dispatch(
				userActions.userTxnFilter({
					...userTransactionFilter,
					page: value,
				})
			);
		}
	};	 

    return (
        <Box m="10px" mb={5}>
            <Box>
                <Typography variant="h4" sx={{ letterSpacing:2 }}> TRANSACTIONS </Typography>
                <Divider></Divider>
            </Box>
            <Spacer space={3}/>
            <Grid container direction="row" alignContent={'center'}>
                <Grid item sm={2} md={2} lg={2}>
                    <ViewTxnAutocomplete
                        name={"txType"}
                        placeholder={"Type"}
                        label=""
                        isCloseIcon={true}
                        isFilter={true}
                        type="txType"
                        optionData={transactionType}
                        selectedVal={transactionType.filter(row => row === userTransactionFilter.txType).map(filteredRow => filteredRow)[0]}
                    >
                    </ViewTxnAutocomplete>
                </Grid>
                <Grid item sm={2} md={2} lg={2} sx={{ ml:1 }}>
                    <ViewTxnAutocomplete
                        name={"txLable"}
                        placeholder={"Label"}
                        label=""
                        isCloseIcon={true}
                        isFilter={true}
                        type="txLable"
                        optionData={labelFilter}
                        selectedVal={labelFilter.filter(row => row === userTransactionFilter.txLable).map(filteredRow => filteredRow)[0]}
                    >
                    </ViewTxnAutocomplete>
                </Grid>
                <Grid item sm={2} md={2} lg={2} sx={{ ml:1 }}>
                    <ViewTxnAutocomplete
                        name={"txSellCurr"}
                        placeholder={"Sell"}
                        label=""
                        isCloseIcon={true}
                        isFilter={true}
                        type="txSellCurr"
                        optionData={sellCurrencyFilter}
                        selectedVal={sellCurrencyFilter.filter(row => row === userTransactionFilter.txLable).map(filteredRow => filteredRow)[0]}
                    >
                    </ViewTxnAutocomplete>
                </Grid>
                <Grid item sm={2} md={2} lg={2} sx={{ ml:1 }}>
                    <ViewTxnAutocomplete
                        name={"txBuyCurr"}
                        placeholder={"Buy"}
                        label=""
                        isCloseIcon={true}
                        isFilter={true}
                        type="txBuyCurr"
                        optionData={buyCurrencyFilter}
                        selectedVal={buyCurrencyFilter.filter(row => row === userTransactionFilter.txLable).map(filteredRow => filteredRow)[0]}
                    >
                    </ViewTxnAutocomplete>
                </Grid>
                <Grid item sm={2} md={2} lg={2} sx={{ ml:1 }}>
                    <ViewTxnAutocomplete
                        name={"txExchange"}
                        placeholder={"Wallet"}
                        label=""
                        isCloseIcon={true}
                        isFilter={true}
                        type="txExchange"
                        optionData={buyWalletFilter}
                        selectedVal={buyWalletFilter.filter(row => row === userTransactionFilter.txLable).map(filteredRow => filteredRow)[0]}
                    >
                    </ViewTxnAutocomplete>
                </Grid>
            </Grid>
            <Spacer space={2}/>
            <Card variant="standard" sx={{ padding: 0}}>
                <CardContent>
                    <TableContainer>
                        <Table aria-label="collapsible table" sx={{ border: 'none', boxShadow: 0,  maxWidth:'1230px'}}>
                            <TableHead sx={{ whiteSpace: 'nowrap', backgroundColor:colors.blueAccent[700] }} >
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pr: 2}}
                                    >
                                        <Typography variant="toolTip">
                                            Type
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0,}}
                                    >
                                        <Typography variant="toolTip">
                                            Label
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pl: 2, pr: 2}}
                                    >                           
                                        <Typography variant="toolTip">
                                            Date
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Input
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Output
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Profit/Loss
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Cost Basis
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Sell Value
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Buy Value
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Sell
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Currency
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Buy
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Currency
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Fee
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Currency
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Wallet
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Free P/L
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:0 }}
                                    >
                                        <Typography variant="toolTip">
                                            Missing Bal
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="center"
                                        sx={{ border: 'none', paddingLeft: 2, pr:3 }}
                                    >
                                        <Typography variant="toolTip">
                                            Fee Missing Bal
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {loading &&
                                        [...Array(10)].map((x, i) => (
                                            <TableRow key={i}>
                                                <TableCell sx={{ width: '15%' , py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '18%', pl:1.5, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        width="100%"
                                                        height={30}
                                                        sx={{
                                                            marginLeft: "5px",
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"90%"}
                                                    /> 
                                                </TableCell>
                                                <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"75%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"80%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"80%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '15%' , py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '18%', pl:1.5, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        width="100%"
                                                        height={30}
                                                        sx={{
                                                            marginLeft: "5px",
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"90%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"80%"}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                        <Skeleton
                                                            animation="wave"
                                                            height={30}
                                                            width={"100%"}
                                                        />
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                    <Stack alignItems={"flex-end"}>
                                                        <Skeleton
                                                            animation="wave"
                                                            height={30}
                                                            width={"100%"}
                                                            />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell sx={{ width: '10%', pr:2, py:1.3 }}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                ))}
                                
                                {!loading && viewTransaction.length > 0 &&
                                    <>
                                        {Object.values(viewTransaction).map((data, i) => {
                                            return(
                                                <>
                                                    {
                                                        data && <ViewTransactionData data={data} key={i}/>
                                                    }
                                                </>
                                            )}
                                        )}
                                    </>
                                }
                            </TableBody>
                        </Table>
                        <Grid container justifyContent={'left'} sx={{padding:'16px 6px 32px 6px'}}>
                            {loading && 
                                <Box>
                                    <Stack ml={1} direction={"row"} gap={1} alignItems={'center'}>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                        <Skeleton variant="circular" animation="wave" height="30px" width="30px"/>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                    </Stack>
                                </Box>
                            }
                            {!loading && viewTransaction.length > 0 &&
                                <Pagination
                                    color="secondary"
                                    count={totalPages}
                                    page={userTransactionFilter.page}
                                    onChange={(e, value) => paginationHandle(value)}
                                />
                            }
                        </Grid>
                    </TableContainer>
                </CardContent>

            </Card>
        </Box>
    )
}

export default ViewUserTrasaction