import React from 'react'
import { Button, Card, CardContent, Box, FormControl, Grid, TextField, Typography, Divider } from '@mui/material'
import { Form, Formik } from 'formik';
import Spacer from '../Spacer/Spacer';
import { getBody } from '../../../_services';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { POST_EDIT_ADMIN_USER } from '../../../_services/users';
import { editAdminUserValidation } from '../../../Validations';
import FormErrorMessage from '../FormErrorMessage/FormErrorMessage';

const EditAdminUser = () => {
    const formRef = useRef();
    const params = useParams()
    const adminUserlist = JSON.parse(localStorage.getItem('admin_user'));
    const adminUserData = adminUserlist?.admins.filter((data) => Number(data.id) === Number(params.id)) 

    const submitEditAdminUser =(fields, actions) => {
        let data = new FormData()
        data.append("admin_id",  params.id )
        data.append("email", fields.email)
        data.append("new_password", fields.new_password)

        POST_EDIT_ADMIN_USER(data)
            .then((res) => {
                res = getBody(res)
                if(res.code === 200){
                }
            })
    }

    return (
        <Box m="10px" mb={5}>
            <Box>
                <Typography variant="h4" sx={{ letterSpacing:2 }}> EDIT ADMIN USERS </Typography>
                <Divider></Divider>
            </Box>
            <Spacer space={3}/>
            <Card variant="standard" sx={{ padding: 0, pb:1}}>
                <CardContent>
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            email: adminUserData[0].email ? adminUserData[0].email : "",
                            new_password: "",
                            confirm_password: "",
                        }}
                        validationSchema={editAdminUserValidation}
                        validateOnChange={true}
                        enableReinitialize={true}
                        onSubmit={(fields, actions) => {
                            submitEditAdminUser(fields,actions);
                        }}
                    >
                        {({ values, isSubmitting, dirty, handleChange, handleBlur, handleSubmit }) => (
                            <Form onSubmit={(e) =>{
                                e.preventDefault(); 
                                handleSubmit();
                            }}>
                                <Grid container justifyContent="center" sx={{ marginBottom: 3 }}>
                                    <Grid item xs={8}>
                                        <Spacer space={2}></Spacer>
                                        <FormControl variant="standard" fullWidth>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                autoComplete="off"
                                                variant="standard"
                                                name="email"
                                                label={"email"}
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values?.email}
                                            />
                                            <FormErrorMessage name={"email"}/>
                                        </FormControl>
                                        <Spacer space={2}></Spacer>
                                        <FormControl variant="standard" fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                autoComplete="off"
                                                label={"New Password"}
                                                size="small"
                                                id="standard-disabled"
                                                type="password"
                                                name="new_password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values?.new_password}
                                            />
                                            <FormErrorMessage name={"new_password"}/>
                                        </FormControl>
                                        <Spacer space={2}></Spacer>
                                        <FormControl variant="standard" fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                autoComplete="off"
                                                label={"New Confirm Password"}
                                                size="small"
                                                id="standard-disabled"
                                                type="password"
                                                name="confirm_password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values?.confirm_password}
                                            />
                                            <FormErrorMessage name={"confirm_password"}/>
                                        </FormControl>
                                        <Spacer space={2}></Spacer>  
                                    </Grid>
                                </Grid>
                                <Spacer space={2} />
                                <Box textAlign={"center"}>
                                    <Button 
                                        type="submit" 
                                        variant="text" 
                                        sx={{ width:"150px" }} 
                                        color="secondary" 
                                        size="medium"
                                        disabled={
                                            isSubmitting || !dirty
                                        }
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
    </Box>
    )
}

export default EditAdminUser
