import React, { useEffect, useState } from "react";
import { Divider, Pagination, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { getBody } from "../../../_services";
import {
	Typography,
	Card,
	Skeleton,
	Table,
	TableHead,
	TableRow,
	TableCell,
	CardContent,
	TableBody,
	Stack,
	Grid,
	TableContainer,
    Box
} from "@mui/material";
import { useDispatch } from "react-redux";
import { REQUEST_POST_UNVERIFIED_USERS } from "../../../_services/users";
import { userActions } from "../../../_actions";
import UnverifiedUsersData from "./UnverifiedUsersData";
import Spacer from "../Spacer/Spacer";

const UnverifiedUsers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
	const [loading, setLoading] = useState(true)
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPage] = useState(1);
    const [unverifiedUsers, setUnverifiedUsers] = useState([]);

    useEffect(() => {
        REQUEST_POST_UNVERIFIED_USERS({page: 1, limit:10})
        .then((res) => {
            res = getBody(res);
            const getResData = res.data;
            if(res.code === 200){
                dispatch(userActions.unverifiedUsersTbl(getResData?.unverifiedUserList));
                setTotalPage(Math.ceil(getResData.totalCount / pageSize))
                setUnverifiedUsers(getResData?.unverifiedUserList);
                setLoading(false);
            }
        })
    }, [])

    const paginationHandle = (event, value) => {
        setLoading(true);
        setPage(value);
        REQUEST_POST_UNVERIFIED_USERS({page:value, limit:10})
        .then((res) =>{
            res = getBody(res);
            const getResData = res.data;
            if(res.code === 200){
                dispatch(userActions.adminUsersTbl(getResData?.unverifiedUserList));
                setTotalPage(Math.ceil(getResData.totalCount / pageSize))
                setUnverifiedUsers(getResData?.unverifiedUserList)
                setLoading(false);
            }
        })
    };

    return (
        <Box m="10px" mb={5}>
            <Box>
                <Typography variant="h4" sx={{ letterSpacing:2 }}> UNVERIFIED USERS </Typography>
                <Divider></Divider>
            </Box>
            <Spacer space={3}/>
            <Card variant="standard" sx={{ padding: 0}}>
                <CardContent >
                    <TableContainer>
                        <Table aria-label="collapsible table" sx={{ border: 'none', boxShadow: 0,  maxHeight: "300px"}}>
                            <TableHead sx={{ whiteSpace: 'nowrap', backgroundColor:colors.blueAccent[700] }} >
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pr: 2}}
                                    >
                                        <Typography variant="toolTip">
                                            Date
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0,}}
                                    >
                                        <Typography variant="toolTip">
                                            User
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Actions
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {loading &&
                                [...Array(5)].map((x, i) => (
                                    <TableRow key={i}>
                                        <TableCell sx={{ width: '5%' , py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"75%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '19%', pr:0, py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"75%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '9%', pl:1, py:1.3 }}>
                                            <Stack direction={"row"} alignItems={'left'}>
                                                <Skeleton
                                                    animation="wave"
                                                    width="100%"
                                                    height={30}
                                                    sx={{
                                                        marginLeft: "5px",
                                                    }}
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                            ))}
                            {!loading && unverifiedUsers.length > 0 &&
                                <TableBody>
                                        {Object.values(unverifiedUsers).map((data, i) => {
                                            return(
                                                <>
                                                    {
                                                        data && <UnverifiedUsersData data={data} key={i}/>
                                                    }
                                                </>
                                        )})}
                                </TableBody>
                            }
                        </Table>
                        
                        <Grid container justifyContent={'left'} sx={{padding:'16px 6px 32px 6px'}}>
                            {loading && 
                                <Box>
                                    <Stack ml={1} direction={"row"} gap={1} alignItems={'center'}>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                        <Skeleton variant="circular" animation="wave" height="30px" width="30px"/>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                    </Stack>
                                </Box>
                            }

                            {!loading && unverifiedUsers.length > 0 &&
                                <Pagination
                                    color="secondary"
                                    count={totalPages}
                                    page={page}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    onChange={paginationHandle}
                                />
                            }
                        </Grid>
                        
                        {!loading && unverifiedUsers.length <= 0  &&
                            <Grid container textAlign={'center'}>
                                <Grid item xs={12}>
                                    <Spacer space={8}/>
                                    <Typography variant='h4'> Unverified Users data Not Found </Typography>
                                    <Spacer space={11}/>
                                </Grid>
                            </Grid>
                        }
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
};

export default UnverifiedUsers;
