import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import moment from "moment";

export const ProgressCircle = ({ progress = "0.75", size = "40" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress * 360;
  return (
    <Box
      sx={{
        background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${colors.blueAccent[500]} ${angle}deg 360deg),
            ${colors.greenAccent[500]}`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export const canAccess = (authentication) => {
	let access = false;
	if(authentication?.loggedIn){
		access = true;
	}
	return access;
}

export const onlyUserAccess = (authentication) => {
	let access = false;
	if(
		authentication.loggedIn &&
		authentication.metaData &&
		authentication.metaData.account_access === null &&
		authentication.metaData.current_user_type === 'user'
	){
		access = true;
	}
	return access;
}

export function thousandSeparator(number, decimals, minimumFractionDigits = 2  ) {
	return number?.toLocaleString('en-US', {minimumFractionDigits:minimumFractionDigits, maximumFractionDigits: decimals})
}

export function getDate(minimumYear) {
	const currentYear = parseInt(moment().format("YYYY"));

	let taxYearRange = [];
	for (let i = currentYear; i >= minimumYear; i--) {
		taxYearRange.push(i)
	}
	return taxYearRange;
}

export const downloadFile = (filePath) => {
	var url = filePath;
	var filename = filePath.substring(filePath.lastIndexOf('/')+1);
	var req = new XMLHttpRequest();
	req.open("GET", url, true);
	req.responseType = "blob";
	req.onload = function () {
		var blob = new Blob([req.response], { type: "application/x-pdf"});
		var isIE = false || !!document.documentMode;
		if (isIE) {
			window.navigator.msSaveBlob(blob);
		} else {
			var url = window.URL || window.webkitURL;
			var link = url.createObjectURL(blob);
			var a = document.createElement("a");
			a.setAttribute("download", filename.split('_').join(' '));
			a.setAttribute("href", link);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};
	req.send();
};
