
import { userConstants } from "../_constants";

export const userActions = {
    allUserPagination,
    allUserFilter,
    removeAllUserFilter,
	editUserDetails,
    allAccountantsTbl,
    adminUsersTbl,
    unverifiedUsersTbl,
    taxReportTbl,
    deletedUsersTbl,
    deleteUsersFilter,
    removeDeleteUsersFilter,
    updateDeletedUsers,
    pendingDelUsers,
    partnerUsers,
    apiLimitBlockchainDialog,
	userTaxPlanDialog,
	userViewTransaction,
	userTxnFilter,
	downloadTaxFormDialog,
	taxReportListFilter,
	removeTaxReportListFilter,
	deleteUserAccountDialog
};


// All-Users

function allUserPagination(data) {
	return { type: userConstants.ALLUSER_PAGINATION, data };
}

function allUserFilter(data) {
	return { type: userConstants.ALLUSER_FILTER, data };
}

function removeAllUserFilter() {
	return { type: userConstants.REMOVE_ALLUSER_FILTER };
}

function editUserDetails(data) {
	return { type: userConstants.EDIT_USER_DETAILS, data};
}

function apiLimitBlockchainDialog(details) { 
	return {
		type: userConstants.API_LIMIT_BLOCKCHAIN_DIALOG, details};
}

function userTaxPlanDialog(details) { 
	return {
		type: userConstants.USER_TAX_PLAN_DIALOG, details};
}

function userViewTransaction(data) {
	return { type: userConstants.USER_VIEW_TRANSACTION, data };
}

function userTxnFilter(data) {
	return { type: userConstants.USER_VIEW_TXN_FILTER, data };
}

function downloadTaxFormDialog(details) { 
	return {type: userConstants.DOWNLOAD_TAX_FORMS_DIALOG, details};
}

function taxReportListFilter(data) {
	return { type: userConstants.TAX_REPORT_LIST_FILTER, data };
}

function removeTaxReportListFilter() {
	return { type: userConstants.REMOVE_TAX_REPORT_LIST_FILTER };
}

function deleteUserAccountDialog(details) { 
	return { type: userConstants.DELETE_USER_ACCOUNT, details};
}


// All-Accountants

function allAccountantsTbl(data) {
	return { type: userConstants.ALL_ACCOUNTANTS, data };
}


// Admin-Users

function adminUsersTbl(data) {
	return { type: userConstants.ADMIN_USERS, data };
}


// Unverified-Users

function unverifiedUsersTbl(data) {
	return { type: userConstants.UNVERIFIED_USERS, data };
}


// Tax-Report

function taxReportTbl(data) {
	return { type: userConstants.TAX_REPORT_TABLE, data };
}


// Deleted-Users

function deletedUsersTbl(data) {
	return { type: userConstants.DELETED_USER_TABLE, data };
}

function deleteUsersFilter(data) {
	return { type: userConstants.DELETED_USER_FILTER, data };
}

function removeDeleteUsersFilter() {
	return { type: userConstants.REMOVE_DELETED_USER_FILTER };
}

function updateDeletedUsers(details) {
	return { type: userConstants.UPDATE_DELETED_USERS, details };
}


// Pending-Deleted-Users

function pendingDelUsers(details) {
	return { type: userConstants.UPDATE_PENDING_DELETED_USERS, details };
}


// Partner-Users

function partnerUsers(details) {
	return { type: userConstants.PARTNER_USERS, details };
}