import { TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import moment from "moment";

const TaxReportData = (props) => {
    const data = props?.data;

    return (
        <React.Fragment key={props.key}>
            <TableRow>
                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"6%"}}
                >
                    {data?.datetime}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2,pr:0, width:"2%"}}
                >
                    {data?.email}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2,pr:0, width:"2%"}}
                >
                    {data?.name}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, pr:0, width:"2%"}}
                >
                    {data?.year}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"2%"}}
                >
                    {data?.ip}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"1%"}}
                >
                    {data?.requested_by}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"1%"}}
                >
                    -
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default TaxReportData