import React from 'react'
import { TableCell, TableRow, Typography } from '@mui/material'
import moment from "moment";

const DeletedUserData = (props) => {
    const data = props?.data;
    
    return (
        <React.Fragment key={props.key}>
            <TableRow>
                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {data?.firstname}
                    <Typography variant="subtitle2" color={"secondary"} > {data?.email}</Typography>
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1,pr:0, width:"2%"}}
                >
                    {data?.country}
                    {!Boolean(data?.country) && "-"}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1,pr:0, width:"1%"}}
                >
                    {data?.plans.length > 0 ? "Yes" : "-"}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, pr:0, width:"1%"}}
                >
                    {data?.deleted_at === null ? "--" : moment((data?.deleted_at)).format("MM-DD-YYYY")}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {data?.delete_reason === null ? "-" : data?.delete_reason}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"10%"}}
                >
                    {data?.feedback === null ? "-" : data?.feedback}
                </TableCell>
                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"0%"}}
                >
                    -
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default DeletedUserData