import { combineReducers } from "redux";
import { login } from './login.reducers';
import { allUserPagination, allUserFilter, allAccountantsTbl, adminUsersTbl, unverifiedUsersTbl, taxReportTbl, deletedUsersTbl, deleteUsersFilter,
    updateDeletedUsers, pendingDelUsers, partnerUsers, apiLimitBlockchainDialog, editUserDetails, userTaxPlanDialog, userViewTransaction, userTxnFilter,
    downloadTaxFormDialog, taxReportListFilter, deleteUserAccountDialog } from './user.table.reducers';

const allReducers = combineReducers({
    login,
    allUserPagination,
    allUserFilter,
    allAccountantsTbl,
    adminUsersTbl,
    unverifiedUsersTbl,
    taxReportTbl,
    deletedUsersTbl,
    deleteUsersFilter,
    updateDeletedUsers,
    pendingDelUsers,
    partnerUsers,
    apiLimitBlockchainDialog,
    editUserDetails,
    userTaxPlanDialog,
    userViewTransaction,
    userTxnFilter,
    downloadTaxFormDialog,
    taxReportListFilter,
    deleteUserAccountDialog
})

const rootReducer = (state, action) => {
    return allReducers(state, action);
};

export default rootReducer;
