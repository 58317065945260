import { TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import moment from "moment";
import { thousandSeparator } from '../../../_helpers/common-helpers';

const ViewTransactionData = (props) => {

    const data = props?.data;

    return (
        <React.Fragment key={props.key}>
            <TableRow>
                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"0%"}}
                >
                    {data?.transaction_type}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1,pr:0, width:"2%"}}
                >
                    {data?.transaction_tag === "" ? "-" : data?.transaction_tag}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1,pr:0, width:"5%"}}
                >
                    <Typography variant="body2" sx={{ width: "70px" }}>
                        {data?.transaction_date}
                    </Typography>
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, pr:0, width:"2%"}}
                >
                    {thousandSeparator(data?.totalinput, 4, 4, 2)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {thousandSeparator(data?.totaloutput, 4, 4, 2)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {thousandSeparator(data.profit_loss_price, 4, 4, 2)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {thousandSeparator(data?.total_price, 4, 4, 2)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {Number(data?.sell_value).toFixed(4)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"2%"}}
                >
                    {Number(data?.buy_value).toFixed(4)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"0%"}}
                >
                    {Number(data?.sell_quantity).toFixed(4)}
                </TableCell>
                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {data?.sell_currency}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {Number(data?.buy_quantity).toFixed(4)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {data?.buy_currency}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {(data?.fee_amount, 4, 4, 2)?.toFixed(4)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    { data?.fee_currency === null ? "-" : data?.fee_currency}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {data?.exchange_name}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {thousandSeparator(data?.fee_profit_loss, 4, 4, 2)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"1%"}}
                >
                    {Number(data?.missing_amount, 4, 4, 2)?.toFixed(4)}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 1, width:"0%"}}
                >
                    {Number(data?.missing_fee_amount)?.toFixed(4)}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default ViewTransactionData