import React from "react";
import { useRoutes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./Components/Dashboard/Dashboard";
import AllUser from "./Components/User/AllUser";
import "./../index.css";
import AllAccountants from "./Components/User/AllAccountants";
import AdminUsers from "./Components/User/AdminUsers";
import UnverifiedUsers from "./Components/User/UnverifiedUsers";
import TaxReports from "./Components/User/TaxReports";
import DeletedUsers from "./Components/User/DeletedUsers";
import PendingDeletedUsers from "./Components/User/PendingDeletedUsers";
import PartnerUsers from "./Components/User/PartnerUsers";
import Login from "./Components/Login/Login";
import PublicRoute from "./PublicRoute";
import EditUsers from "./Components/User/EditUsers";
import ViewUserTrasaction from "./Components/User/ViewUserTrasaction";
import GenerateTaxReport from "./Components/User/GenerateTaxReport";
import EditAdminUser from "./Components/User/EditAdminUser";

const Front = () => {

  const privateRoutes = [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },{
      path: "/users",
      element: <AllUser />,
    },{
      path: "/edit-user/:id",
      element: <EditUsers />,
    },{
      path: "/transaction/:id",
      element: <ViewUserTrasaction />,
    },{
      path: "/user-reports/:id",
      element: <GenerateTaxReport />,
    },{
      path: "/accountants",
      element: <AllAccountants />,
    },{
      path: "/adminusers",
      element: <AdminUsers />,
    },{
      path: "/admin-users/edit/:id",
      element: <EditAdminUser />,
    },{
      path: "/unverified-users",
      element: <UnverifiedUsers />,
    },{
      path: "/tax-reports",
      element: <TaxReports />,
    },{
      path: "/deleted-users",
      element: <DeletedUsers />,
    },{
      path: "/pending-deleted-users",
      element: <PendingDeletedUsers />,
    },{
      path: "/partner-users",
      element: <PartnerUsers />,
    },
  ];

  const publicRoutes = [
    {
      path: "/login",
      element: <Login />,
    },
  ];



const routes = useRoutes([
    ...privateRoutes.map((route) => {
        const routeObject = {
            path: route.path,
            element: <PrivateRoute redirectTo={"/login"}>{route.element}</PrivateRoute>
        }
        return routeObject;
    }),

    ...publicRoutes.map((route) => {
        const routeObject = {
            path: route.path,
            element: <PublicRoute>{route.element}</PublicRoute>
        }
        return routeObject;
    }),
]);
  return routes;
}

export default Front;