import React, { useState } from "react";
import {
	Box,
	InputBase,
	ClickAwayListener,
	InputLabel,
	Grid,
	Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { StyledPopper, StyledAutocompletePopper, AutoCompleteIconButton, AutoCompleteButton } from "../CustomDesignComponent";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { userActions } from "../../../_actions";
import { ErrorMessage } from "formik";

const TaxReportListAutocomplate = (props) => {

    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
    const [requestList, setRequestList] = useState({});
    const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;
    const taxReportListFilter = useSelector((state) => state.taxReportListFilter);
	const name = props.name;

    function PopperComponent(props) {
		const { disablePortal, anchorEl, open, ...other } = props;
		return <StyledAutocompletePopper {...other} />;
	}

	PopperComponent.propTypes = {
		anchorEl: PropTypes.any,
		disablePortal: PropTypes.bool,
		open: PropTypes.bool.isRequired,
	};

	const StyledInput = styled(InputBase)(({ theme }) => ({
		width: "100%",
		"& input": {
			display: "none"
		},
	}));

    const handleSelectReason = (select) => {
		if(taxReportListFilter.requested_from !== select.key){
			setRequestList(select);
			dispatch(userActions.taxReportListFilter({
                ...taxReportListFilter,
                requested_from : select.key,
                page : 1
            }))
		}
		handleClose();
	}

    const handleCategoryButtonClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setAnchorEl(null);
	};

    const handleCloseButton = () => {
		setIsOpen(false);
		setRequestList({});
		setAnchorEl(null);
		dispatch(userActions.taxReportListFilter({
			...taxReportListFilter,
			requested_from : null,
			page : 1
		}))
	};

    return (
        <React.Fragment>
            <InputLabel sx={{ fontSize: "12px", color: "text.secondary" }}>
                {props.label}
            </InputLabel>
            <Box position={"relative"}>
                <AutoCompleteButton
                    sx={{
                        "&.MuiButtonBase-root:hover": {
                            backgroundColor: "primary.12p",
                        }
                    }}
                    endIcon={ open ? <ArrowDropUp style={{fontSize: '24px', right:"12px"}} />: <ArrowDropDown style={{fontSize: '24px', right:"12px"}} />}
                    onClick={handleCategoryButtonClick}
                    fullWidth
                    aria-describedby={id}
                    variant="standard"
                >
                    {requestList.name ? <Typography sx={{ color: 'text.primary', py: 0.25 }} variant="body2">{requestList.name}</Typography> : <Typography sx={{ color: 'text.secondary', py: 0.25 }} variant="body2">{props.placeholder}</Typography>}
                </AutoCompleteButton>
                {requestList.name && props.isCloseIcon && (
                    <AutoCompleteIconButton  onClick={handleCloseButton}>
                        <CloseIcon />
                    </AutoCompleteIconButton>
                )}
            </Box>
            {isOpen && (
                <StyledPopper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={[
                        {
                        name: "sameWidth",
                        enabled: true,
                        fn: ({ state }) => {
                            state.styles.popper.width = `${state.rects.reference.width}px`;
                        },
                        phase: "beforeWrite",
                        requires: ["computeStyles"],
                        effect: ({ state }) => {
                            state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                        }
                        }
                    ]}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            <Autocomplete
                                open
                                options={props.optionData?props.optionData:[]}
                                disableCloseOnSelect
                                PopperComponent={PopperComponent}
                                noOptionsText={"Not Found"}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => handleSelectReason(value)}
                                value={props.selectedVal?props.selectedVal:null}
                                renderOption={(props, option) => (
                                    <>
                                        <Grid container justifyContent="left" alignItems="center" wrap="nowrap" sx={{ borderRadius: 0,  cursor: 'pointer', backgroundColor: option.key === requestList.key?'primary.8p':''}} {...props} key={option.key}> 
                                            <Grid item xs={12} sx={{ py: 0.75, position: 'relative' }}>
                                                {option.name}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                sx={{
                                    display: 'flex'
                                }}
                                componentsProps={{
                                    popper: {
                                    sx: {
                                        '& .MuiAutocomplete-listbox' : { py: 1 }
                                    }
                                    }
                                }}
                                renderInput={(params) => (
                                    <StyledInput
                                        ref={params.InputProps.ref}
                                        inputProps={params.inputProps}
                                        autoFocus
                                    />
                                )}
                                onClose={(event, reason) => {
                                    if (reason === "escape") {
                                        handleClose();
                                    }
                                }}
                            />
                        </div>
                    </ClickAwayListener>
                </StyledPopper>
            )}

            {!props.isFilter && 
                <ErrorMessage
                    component="div"
                    name={name}
                    style={{ color: 'red', fontSize: "12.5px"}}
                ></ErrorMessage>
            }
        </React.Fragment>
    )
}

export default TaxReportListAutocomplate
