import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, ClickAwayListener, Dialog, DialogContent, DialogTitle, Grid, InputBase, InputLabel, TextField, Typography } from '@mui/material'
import { AutoCompleteButton, AutoCompleteTaxPlanBtn, DialogCloseButton, StyledAutocompletePopper, StyledPopper } from '../../CustomDesignComponent'
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../_actions';
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from 'formik';
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import Spacer from '../../Spacer/Spacer';
import { getBody } from '../../../../_services';
import { useRef } from 'react';
import { POST_DOWNLOAD_TAXREPORT } from '../../../../_services/users';
import { getDate } from '../../../../_helpers/common-helpers';

const DownloadTaxForms = (props) => {

	let taxYearRange = getDate(2017);
	let taxYearRange1 = getDate(2018);
	let taxYearRange2 = getDate(2019);

	const TaxFormList = [
		{name : "Complate Tax Report", year: taxYearRange},
		{name : "ATo Report", year: taxYearRange},
		{name : "Highest Balance Report", year: taxYearRange},
		{name : "Capital Gains Reports", year: taxYearRange},
		{name : "Transaction History", year: taxYearRange},
		{name : "TurboTax Online File", year: taxYearRange},
		{name : "TurboTax CD/Download File", year: taxYearRange},
		{name : "TaxAct Export", year: taxYearRange},
		{name : "IRS Form 8949", year: taxYearRange},
		{name : "IRS Schedule D", year: taxYearRange},
		{name : "IRS Schedule 1", year: taxYearRange1},
		{name : "K4 Blanketten", year: taxYearRange},
		{name : "RF 1159", year: taxYearRange},
		{name : "Formulaire n 2042 C", year: taxYearRange2},
		{name : "CRA Schedule 3", year: taxYearRange},
		{name : "H&R Block Online", year: taxYearRange},
		{name : "Italy 7-Day Balance Report", year: taxYearRange},
	];
    
	const StyledInput = styled(InputBase)(({ theme }) => ({
        width: "100%",
		"& input": {
            display: "none"
		},
	}));

    const formRef = useRef();
	const dispatch = useDispatch();
    const [isTaxFormOpen, setIsTaxFormOpen] = useState(false);
    const [isYearOpen, setIsYearOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorEl1, setAnchorEl1] = useState(null);
    const openTaxForm = Boolean(anchorEl);
    const openYear = Boolean(anchorEl1);
	const id = openTaxForm ? "simple-popper" : undefined;
	const id1 = openYear ? "simple-popper" : undefined;
    const openDownloadTaxForm = useSelector((state) => state.downloadTaxFormDialog);
    const UserId = openDownloadTaxForm?.user_id ? openDownloadTaxForm?.user_id : null;
    const filterTaxFormList = TaxFormList.map((name) => name.name);
    const [optionsSecondAuto, setOptionsSecondAuto] = useState(TaxFormList[0].year.sort());
    const [ selectTaxForm, setSelectTaxForm] = useState({ defaultTaxForm: ""});

    const closeDownloadTaxForm = () => {
        dispatch(userActions.downloadTaxFormDialog({
            isOpen: false,
            step: 1,
			user_id: null
        }))
	}
	function PopperComponent(props) {
		const { disablePortal, anchorEl, open, ...other } = props;
		return <StyledAutocompletePopper {...other} />;
	}

	PopperComponent.propTypes = {
		anchorEl: PropTypes.any,
		disablePortal: PropTypes.bool,
		open: PropTypes.bool.isRequired,
	};

	const openTaxFormOption = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsTaxFormOpen(true);
	};

	const handleTaxFormClose = () => {
		setIsTaxFormOpen(false);
		setAnchorEl(null);
	};

	// =========================================================
	function PopperComponent1(props) {
		const { disablePortal, anchorEl1, open, ...other } = props;
		return <StyledAutocompletePopper {...other} />;
	}

	PopperComponent1.propTypes = {
		anchorEl1: PropTypes.any,
		disablePortal: PropTypes.bool,
		open: PropTypes.bool.isRequired,
	};

	const openYearOption = (event) => {
		setAnchorEl1(anchorEl1 ? null : event.currentTarget);
		setIsYearOpen(true);
	};

	const handleYearClose = () => {
		setIsYearOpen(false);
		setAnchorEl1(null);
	};
	// ============================================================
    useEffect(() => {
        setSelectTaxForm((prevState) => ({
            ...prevState,
            defaultTaxForm: filterTaxFormList[0],
        }));
    }, [])

    const handleTaxFormSelect = (value) => {
		const newYear = TaxFormList.filter((list) => value === list.name);
        const reverseData = newYear[0].year.sort();
		setOptionsSecondAuto(reverseData)

		setSelectTaxForm((prevState) => ({
			...prevState,
			defaultTaxForm: value,
		}));
        handleTaxFormClose();
	}

    const submitUserTaxForm =(fields, actions) => {
        let data = new FormData()
        data.append("user_id", UserId)
        data.append("pdfname",  fields.pdfname)
        data.append("year", fields.year)

        POST_DOWNLOAD_TAXREPORT(data)
            .then((res) => {
                res = getBody(res)
        })

        handleTaxFormClose();
		handleYearClose();
		closeDownloadTaxForm();
    }

    return (
        <Dialog
			open={openDownloadTaxForm.isOpen}
			onClose={closeDownloadTaxForm}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
                Download Tax Forms
				<DialogCloseButton
					aria-label="close"
					onClick={closeDownloadTaxForm}
                    size="small"
				>
					<CloseIcon />
				</DialogCloseButton>
			</DialogTitle>
			<DialogContent dividers >
				<Formik
                    innerRef={formRef}
                    initialValues={{
                        pdfname:  selectTaxForm?.defaultTaxForm ? selectTaxForm?.defaultTaxForm : "",
                        year: optionsSecondAuto[0] ? optionsSecondAuto[0] : "",
                    }}
                    enableReinitialize={true}
                    onSubmit={(fields, actions) => {
                        submitUserTaxForm(fields,actions);
                    }}
                >
                    {({ values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} >
                            <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Spacer space={1}></Spacer>
                                    <Typography align="center" variant="h6" > Tax Form </Typography>
                                    <Spacer space={0.5}></Spacer>
                                    <React.Fragment>
                                        <Box position={"relative"}>
                                            <AutoCompleteTaxPlanBtn
                                                sx={{
                                                    "&.MuiButtonBase-root:hover": {
                                                        backgroundColor: "primary.12p",
                                                    }
                                                }}
                                                endIcon={ openTaxForm ? <ArrowDropUp style={{fontSize: '24px', right:"12px"}} />: <ArrowDropDown style={{fontSize: '24px', right:"12px"}} />}
                                                onClick={openTaxFormOption}
                                                fullWidth
                                                aria-describedby={id}
                                                variant="standard"
                                            >
                                                {/* {reasonData.name ?  */}
                                                    <Typography sx={{ color: 'text.primary'}} variant="h6">{selectTaxForm?.defaultTaxForm}</Typography>
                                                {/* } */}
                                            </AutoCompleteTaxPlanBtn>
                                        </Box>
                                        {isTaxFormOpen && (
                                            <StyledPopper
                                                id={id}
                                                open={openTaxForm}
                                                anchorEl={anchorEl}
                                                placement="bottom-start"
                                                modifiers={[
                                                    {
                                                    name: "sameWidth",
                                                    enabled: true,
                                                    fn: ({ state }) => {
                                                        state.styles.popper.width = `${state.rects.reference.width}px`;
                                                    },
                                                    phase: "beforeWrite",
                                                    requires: ["computeStyles"],
                                                    effect: ({ state }) => {
                                                        state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                                                    }
                                                    }
                                                ]}
                                            >
                                                <ClickAwayListener onClickAway={handleTaxFormClose}>
                                                    <div>
                                                        <Autocomplete
                                                            open
                                                            options={filterTaxFormList}
                                                            disableCloseOnSelect
                                                            name="pdfname"
                                                            PopperComponent={PopperComponent}
                                                            isOptionEqualToValue={(option, value) => {

                                                                return option === value || value === "";
                                                            }}
                                                            getOptionLabel={(option) => option ? option : ""}
                                                            value={selectTaxForm?.defaultTaxForm}
                                                            onChange={(event, value) => {
                                                                setFieldValue("pdfname", value)
                                                                handleTaxFormSelect(value)
															}}
                                                            renderOption={(props, option) => (
																<>
                                                                    <Grid container justifyContent="left" alignItems="center" wrap="nowrap" sx={{ borderRadius: 0,  cursor: 'pointer', backgroundColor:'primary.8p'}} {...props} key={option.key}> 
                                                                        <Grid item xs={12} sx={{ py: 0, position: 'relative' }}>
                                                                            {option}
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            sx={{
                                                                display: 'flex'
                                                            }}
                                                            componentsProps={{
                                                                popper: {
                                                                sx: {
                                                                    '& .MuiAutocomplete-listbox' : { py: 0 }
                                                                }
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <StyledInput
                                                                    ref={params.InputProps.ref}
                                                                    inputProps={params.inputProps}
                                                                    autoFocus
                                                                />
                                                            )}
                                                            onClose={(event, reason) => {
                                                                if (reason === "escape") {
                                                                    handleTaxFormClose();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </ClickAwayListener>
                                            </StyledPopper>
                                        )}
                                    </React.Fragment>
                                </Grid>

								<Grid item xs={7} sm={7} md={7}>
                                    <Spacer space={2}></Spacer>
                                    <Typography align="center" variant="h6" > Year </Typography>
                                    <Spacer space={0.5}></Spacer>
                                    <React.Fragment>
                                        <Box position={"relative"}>
                                            <AutoCompleteTaxPlanBtn
                                                sx={{
                                                    "&.MuiButtonBase-root:hover": {
                                                        backgroundColor: "primary.12p",
                                                    }
                                                }}
                                                endIcon={ openYear ? <ArrowDropUp style={{fontSize: '24px', right:"12px"}} />: <ArrowDropDown style={{fontSize: '24px', right:"12px"}} />}
                                                onClick={openYearOption}
                                                fullWidth
                                                aria-describedby={id1}
                                                variant="standard"
                                            >
                                                <Typography sx={{ color: 'text.primary'}} variant="h6">{values?.year}</Typography>
                                            </AutoCompleteTaxPlanBtn>
                                        </Box>
                                        {isYearOpen && (
                                            <StyledPopper
                                                id={id1}
                                                open={openYear}
                                                anchorEl={anchorEl1}
                                                placement="bottom-start"
                                                modifiers={[
                                                    {
                                                    name: "sameWidth",
                                                    enabled: true,
                                                    fn: ({ state }) => {
                                                        state.styles.popper.width = `${state.rects.reference.width}px`;
                                                    },
                                                    phase: "beforeWrite",
                                                    requires: ["computeStyles"],
                                                    effect: ({ state }) => {
                                                        state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                                                    }
                                                    }
                                                ]}
                                            >
                                                <ClickAwayListener onClickAway={handleYearClose}>
                                                    <div>
                                                        <Autocomplete
                                                            open
                                                            options={optionsSecondAuto}
                                                            disableCloseOnSelect
                                                            name="year"
                                                            PopperComponent={PopperComponent1}
                                                            isOptionEqualToValue={(option, value) => {
                                                                return option === value || value === "";
                                                            }}
                                                            getOptionLabel={(option) => option ? option : ""}
                                                            value={values?.year}
                                                            onChange={(event, value) => {
                                                                setFieldValue("year", value)
                                                                handleYearClose();
															}}
                                                            renderOption={(props, option) => (
                                                                <>
                                                                    <Grid container justifyContent="left" alignItems="center" wrap="nowrap" sx={{ borderRadius: 0,  cursor: 'pointer', backgroundColor:'primary.8p'}} {...props} key={option.key}> 
                                                                        <Grid item xs={12} sx={{ py: 0, position: 'relative' }}>
                                                                            {option}
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            sx={{
                                                                display: 'flex'
                                                            }}
                                                            componentsProps={{
                                                                popper: {
                                                                sx: {
                                                                    '& .MuiAutocomplete-listbox' : { py: 0 }
                                                                }
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <StyledInput
                                                                    ref={params.InputProps.ref}
                                                                    inputProps={params.inputProps}
                                                                    autoFocus
                                                                />
                                                            )}
                                                            onClose={(event, reason) => {
                                                                if (reason === "escape") {
                                                                    handleYearClose();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </ClickAwayListener>
                                            </StyledPopper>
                                        )}
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                            <Box textAlign="center">
                                <Button type="submit" variant="text" color="secondary" size="medium">Generate</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
		</Dialog>
    )
}

export default DownloadTaxForms