import React from 'react'
import { Button, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const AdminUserData = (props) => {
    let history = useNavigate();
    const data = props?.data;

    return (
        <React.Fragment key={props.key}>
            <TableRow >
                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"0%"}}
                >
                    {data?.id}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2,pr:0, width:"2%"}}
                >
                    {data?.email}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, pl:0.4, width:"1%"}}
                >
                    <Button onClick={ () => history(`/admin-users/edit/${data?.id}`)} variant="outlined" size="small" color="info"> Edit </Button>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default AdminUserData