import { Box, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import OutboundIcon from '@mui/icons-material/Outbound';
import { tokens } from '../../../theme';
import { useNavigate } from "react-router-dom";
import { POST_USER_LOGIN, REQUEST_POST_USER_DETAILS } from '../../../_services/users';
import { getBody } from '../../../_services';
import { userActions } from '../../../_actions';
import { useDispatch } from 'react-redux';
import DownloadTaxForms from './Dialogs/DownloadTaxForms';
import DeleteUserAccount from './Dialogs/DeleteUserAccount';

const AllUserData = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let history = useNavigate();
	const dispatch = useDispatch();
    const REACT_LIVE_APP_URL = process.env.REACT_APP_LIVE_URL;
    const data = props?.data;

    const userLogin = (userID) => {

        POST_USER_LOGIN({user_id:userID})
        .then((res) =>{
            res = getBody(res);
            if(res.code === 200){

                var receiverWindow = window.open(REACT_LIVE_APP_URL, '_blank');
                const receiverIframe = document.getElementById("receiver-iframe");
                receiverIframe.contentWindow.postMessage(res.data, REACT_LIVE_APP_URL);

                // Wait for the receiver window to load and then post the message
                receiverWindow.addEventListener('load', function() {
                    console.log(res.data);
                });
            }
        })
    }

    const openDownloadTaxForm = (userId) => {
        dispatch(userActions.downloadTaxFormDialog({
            isOpen: true,
            step: 1,
            user_id: userId
        }))
    }

    const openDeleteAccountDialog = (userId) => {
        dispatch(userActions.deleteUserAccountDialog({
            isOpen: true,
            step: 1,
            user_id: userId
        }))
    }

    return (
        <>
            <React.Fragment key={props.key}>
                <TableRow>
                    <TableCell
                        align={"left"}
                        sx={{ py: 1, width:"0%"}}
                    >
                        {data?.id}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1,pr:0, width:"1%"}}
                    >
                        {data?.firstname}
                        <Typography variant="subtitle2" color={"secondary"} > {data?.email}</Typography>
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1,pr:0, width:"1%"}}
                    >
                        {data?.country}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1, pr:0, width:"1%"}}
                    >
                        {data?.basecurrency}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1, width:"1%"}}
                    >
                        {data?.costbasis}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1, width:"1%"}}
                    >
                        {data?.transactions_count}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1, width:"1%"}}
                    >
                        {data?.partner_source === null ? "-" : data?.partner_source}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1,pr:0, width:"1%"}}
                    >
                        {data?.created_at === null ? "--" : moment((data?.created_at)).format("MM-DD-YYYY")}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 0, pr:1, width:"0%"}}
                    >
                        <Box>
                            <Stack direction="row">
                                <Tooltip title="Edit" arrow>
                                    <IconButton onClick={ () => history(`/edit-user/${data?.id}`)} sx={{color:"#03a9f4"}} size="small">
                                        <EditIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="View" arrow>
                                    <IconButton onClick={ () => history(`/transaction/${data?.id}`)} sx={{color:"#1de9b6"}} size="small">
                                        <VisibilityIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Login User" arrow>
                                    <IconButton onClick={() => userLogin(data?.id)} sx={{ color: "#f9a825" }} size="small">
                                        <OutboundIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Download" arrow>
                                    <IconButton onClick={() => openDownloadTaxForm(data?.id)} sx={{ color:"#9e9e9e" }} size="small">
                                        <FileDownloadIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Reports" arrow>
                                    <IconButton onClick={ () => history(`/user-reports/${data?.id}`)} sx={{ color:"#b388ff" }} size="small">
                                        <InsertDriveFileIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete" arrow>
                                    <IconButton onClick={() => openDeleteAccountDialog(data?.id)} color="error" size="small">
                                        <DeleteIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    </TableCell>
                </TableRow>
                <DownloadTaxForms></DownloadTaxForms>
                <Grid sx={{"visibility" : "hidden", "height" : 0, "width": "0"}}>
                <iframe
                    id="receiver-iframe"
                    src={REACT_LIVE_APP_URL}
                />
                </Grid>
            </React.Fragment>
            <DownloadTaxForms></DownloadTaxForms>
            <DeleteUserAccount deleteAccount={props.deleteAccount}></DeleteUserAccount>
        </>
    )
}

export default AllUserData
