import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, Box, FormControl, Grid, Paper, Stack, TextField, Typography, Divider, Skeleton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import Spacer from '../Spacer/Spacer';
import { ChipStyled } from '../CustomDesignComponent';
import ApiLimitBlockchain from './Dialogs/ApiLimitBlockchain';
import { userActions } from '../../../_actions';
import { getBody } from '../../../_services';
import { REQUEST_POST_USER_DETAILS, REQUEST_POST_USER_DETAILS_UPDATE } from '../../../_services/users';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import UserTaxPlan from './Dialogs/UserTaxPlan';

const EditUsers = () => {

    const formRef = useRef();
    const dispatch = useDispatch();
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [userDetails, setUserDetails] = useState({})
    const blockchainLimit = userDetails?.api_limit_blockchains ? userDetails?.api_limit_blockchains : "null";
    const userTaxPlan = userDetails?.taxPlanData ? userDetails?.taxPlanData : "null";
    const [isUpdate, setIsUpdate] = useState(false);
	const handleEditUserTbl = () => {
		setIsUpdate(!isUpdate);
	}

    const openApiLimitBlockchain = () => {
        dispatch(userActions.apiLimitBlockchainDialog({
            isOpen: true,
            step: 1
        }))
    }

    const openUserTaxPlan = (name) => {
        dispatch(userActions.userTaxPlanDialog({
            isOpen: true,
            step: 1,
            name: name
        }))
    }

    useEffect(() => {
        REQUEST_POST_USER_DETAILS({user_id: params.id}).then((res) => {
            res = getBody(res)
            if(res.code === 200){
                dispatch(userActions.editUserDetails(res.data));
                setUserDetails(res?.data)
            }
        })
    }, [isUpdate])


    const submitEditUser =(fields, actions) => {
        let data = new FormData()
        data.append("user_id",  params.id )
        data.append("firstname", fields.firstname)
        if(!Boolean(userDetails.affiliate_status)){
            data.append("credit", fields.credit)
        }
        data.append("referral_code", fields.referral_code)

        REQUEST_POST_USER_DETAILS_UPDATE(data)
            .then((res) => {
                res = getBody(res)
                if(res.code === 200){
                    handleEditUserTbl()
                }
            })
    }

    return (
        <Box m="10px" mb={5}>
            <Box>
                <Typography variant="h4" sx={{ letterSpacing:2 }}> EDIT USERS </Typography>
                <Divider></Divider>
            </Box>
            <Spacer space={3}/>
                <Card variant="standard" sx={{ padding: 0}}>
                    <CardContent>
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                firstname: userDetails?.firstname ? userDetails?.firstname : "",
                                credit: userDetails?.credit_referral ? userDetails?.credit_referral : "N/A",
                                referral_code: userDetails?.referral_url ? userDetails?.referral_url : "",
                            }}
                            validateOnChange={false}
                            validator={() => ({})}
                            enableReinitialize={true}
                            onSubmit={(fields, actions) => {
                                submitEditUser(fields,actions);
                            }}
                        >
                            {({ values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
                                <Form onSubmit={(e) =>{
                                    e.preventDefault(); 
                                    handleSubmit();
                                }}>
                                    <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                                        <Grid item xs={7}>
                                            <FormControl variant="standard" fullWidth>
                                                {console.log(values)} 
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    autoComplete="off"
                                                    variant="standard"
                                                    name="firstname"
                                                    label={"Name"}
                                                    size="small"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values?.firstname}
                                                />
                                                {/* <FormErrorMessage name={"name"}/> */}
                                            </FormControl>
                                            <Spacer space={1.5}></Spacer>
                                            <FormControl variant="standard" fullWidth>
                                                <TextField
                                                    disabled={true}
                                                    fullWidth
                                                    variant="standard"
                                                    label={"Email"}
                                                    size="small"
                                                    id="standard-disabled"
                                                    value={String(userDetails?.email)}
                                                />
                                            </FormControl>
                                            <Spacer space={1.5}></Spacer>

                                            <Stack direction="row" gap={1} alignItems={'center'}>
                                                <FormControl variant="standard" fullWidth>
                                                    <TextField
                                                        disabled={true}
                                                        fullWidth
                                                        variant="standard"
                                                        label={"Country"}
                                                        size="small"
                                                        value={String(userDetails?.country)}
                                                    />
                                                </FormControl>

                                                <FormControl variant="standard" fullWidth>
                                                    <TextField
                                                        disabled={true}
                                                        fullWidth
                                                        variant="standard"
                                                        label={"Base Currency"}
                                                        size="small"
                                                        value={String(userDetails?.basecurrency)}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Spacer space={1.5}></Spacer>

                                            <Stack direction="row" gap={1} alignItems={'center'}>
                                                <FormControl variant="standard" fullWidth>
                                                    <TextField
                                                        disabled
                                                        fullWidth
                                                        variant="standard"
                                                        label={"Transactions"}
                                                        size="small"
                                                        value={Number(userDetails?.transactions_count)}
                                                    />
                                                </FormControl>
                                                <FormControl variant="standard" fullWidth>
                                                    <TextField
                                                        disabled
                                                        fullWidth
                                                        variant="standard"
                                                        label={"Referrals"}
                                                        size="small"
                                                        value={Number(userDetails?.referrals)}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Spacer space={1.5}></Spacer>

                                            <Stack direction="row" gap={1} alignItems={'center'}>
                                                <FormControl variant="standard" fullWidth>
                                                    <TextField
                                                        disabled
                                                        fullWidth
                                                        variant="standard"
                                                        label={"Conversions"}
                                                        size="small"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={Number(userDetails?.conversions)}
                                                    />
                                                </FormControl>
                                                <FormControl variant="standard" fullWidth>
                                                    <TextField
                                                        disabled={userDetails.affiliate_status ? true : false}
                                                        fullWidth
                                                        variant="standard"
                                                        label={"Credit(Referral)"}
                                                        size="small"
                                                        name={"credit"}
                                                        id="credit"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values?.credit}
                                                    />
                                                </FormControl>
                                                <FormControl variant="standard" fullWidth>
                                                    <TextField
                                                        disabled={true}
                                                        fullWidth
                                                        variant="standard"
                                                        label={"Commission(Affiliate)"}
                                                        size="small"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={userDetails?.commission_affiliate === null ? "N/A" : `$${String(userDetails?.commission_affiliate)}`}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Spacer space={1.5}></Spacer>

                                            <FormControl variant="standard" fullWidth>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="off"
                                                    variant="standard"
                                                    name="referral_code"
                                                    id={"referral_code"}
                                                    label={"Referral URL"}
                                                    size="small"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values?.referral_code}
                                                />
                                                {/* <FormErrorMessage name={"name"}/> */}
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                        <Grid item textAlign={"-webkit-center"} xs={12} sm={12} md={7} lg={7}>
                                            <Paper sx={{ width: "100%" , p:1, textAlign:"center" }}>
                                                <Typography  variant="subtitle2" gutterBottom>API limit blockchains</Typography>
                                                <Stack direction="row" gap={1} justifyContent="center" alignItems={'center'}>
                                                    <ChipStyled variant="outlined" label={blockchainLimit?.eth_bsc_limit}></ChipStyled>
                                                    <ChipStyled variant="outlined" label={blockchainLimit?.blockchain_limit}></ChipStyled>
                                                </Stack>
                                                <Spacer space={1} />
                                                <Button onClick={openApiLimitBlockchain} variant="text" color="secondary" size="small">Update</Button>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Spacer space={1} />
                                    
                                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                        <Grid item textAlign={"-webkit-center"} xs={12} sm={12} md={7} lg={7}>
                                            {Object.values(userTaxPlan).map((data, i) => (
                                                <>
                                                    <Paper sx={{ width: "100%", p:1, textAlign:"center" }}>
                                                        <Typography  variant="subtitle2" gutterBottom>Tax Plan {data?.user_plan?.plan_year}</Typography>
                                                        <Stack direction="row" gap={1} justifyContent="center" alignItems={'center'} mb={1}>
                                                            <ChipStyled variant="outlined" label={data?.user_plan?.plan_name}></ChipStyled>
                                                            <ChipStyled variant="outlined" label={data?.total_transactions}></ChipStyled>
                                                        </Stack>
                                                        <Button onClick={(e) => openUserTaxPlan(data)} variant="text" color="secondary" size="small">Update</Button>
                                                    </Paper>
                                                    <Spacer space={1} />
                                                </>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Spacer space={2} />
                                    <Box textAlign={"center"}>
                                        <Button type="submit" variant="text" sx={{ width:"200px", paddingTop:1.2 }} color="secondary" size="large"> Save </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            {/* } */}

            {/* {!userDetails && 
                <Card variant="standard" sx={{ padding: 0}}>
                    <CardContent>
                    {[...Array(5)].map((_x, i) => (
									<React.Fragment key={i}>
										<Stack spacing={4}>
											<Skeleton
												variant="rectangular"
												height={40}
											/>
										</Stack>
										<Spacer space={4}/>
									</React.Fragment>
								))}
                    </CardContent>
                </Card>
            } */}
            <ApiLimitBlockchain blockchainLimit={blockchainLimit} id={params.id} handleApiBlcLimit={handleEditUserTbl}></ApiLimitBlockchain>
            <UserTaxPlan handleTaxPlan={handleEditUserTbl}></UserTaxPlan>
        </Box>
    )
}

export default EditUsers