import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import moment from "moment";

const UnverifiedUsersData = (props) => {
    const data = props?.data;
    return (
        <React.Fragment key={props.key}>
            <TableRow >
                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"1%"}}
                >
                    {data?.created_at === null ? "--" : moment((data?.created_at)).format("MM-DD-YYYY")}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2,pr:0, width:"4%"}}
                >
                    {data?.email}
                </TableCell>

                <TableCell
                    align={"left"}
                    sx={{ py: 2, width:"2%"}}
                >
                    -
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default UnverifiedUsersData