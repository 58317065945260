import * as Yup from "yup";

export const editAdminUserValidation = () => Yup.object({
	email: Yup.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
    new_password: Yup.string()
        .required('Please enter your password.')
        .max(20, 'Password must not exceed 20 characters'),
    confirm_password: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('new_password')], 'Your passwords do not match.')
});