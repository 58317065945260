import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, ClickAwayListener, Dialog, DialogContent, DialogTitle, Grid, InputBase, InputLabel, TextField, Typography } from '@mui/material'
import { AutoCompleteButton, AutoCompleteTaxPlanBtn, DialogCloseButton, StyledAutocompletePopper, StyledPopper } from '../../CustomDesignComponent'
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../_actions';
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from 'formik';
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import Spacer from '../../Spacer/Spacer';
import { getBody } from '../../../../_services';
import { REQUEST_POST_UPDATE_TAX_PLAN } from '../../../../_services/users';
import { useRef } from 'react';

const UserTaxPlan = (props) => {

    const planList = [
		{
			key: 1,
			name: "Free",
		},{
			key: 2,
			name: "Hodler",
		},{
			key: 3,
			name: "Trader",
		},{
			key: 4,
			name: "Pro",
		},{
			key: 5,
			name: "Satoshi",
		}
	];

    const formRef = useRef();
	const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;
    const openTaxPlan = useSelector((state) => state.userTaxPlanDialog);
    const userPlan = openTaxPlan?.name ? openTaxPlan?.name?.user_plan : null;
    const [ selectTaxPLan, setSelectTaxPlan] = useState({ defaultValue: "", planId: ""});
    const filterplan = planList.filter((planName) => planName?.name === userPlan?.plan_name);

    const handleTaxPlan = () => {
		props.handleTaxPlan();
	}

    useEffect(() => {
        setSelectTaxPlan((prevState) => ({
            ...prevState,
            defaultValue: filterplan[0]?.name ? filterplan[0]?.name : "",
        }));
    }, [userPlan])

    const handleTaxPlanSelect = (value) => {
		setSelectTaxPlan((prevState) => ({
			...prevState,
			defaultValue:value?.name,
            planId: value?.key
		}));
        handleClose();
	}

    function PopperComponent(props) {
		const { disablePortal, anchorEl, open, ...other } = props;
		return <StyledAutocompletePopper {...other} />;
	}

	PopperComponent.propTypes = {
		anchorEl: PropTypes.any,
		disablePortal: PropTypes.bool,
		open: PropTypes.bool.isRequired,
	};

	const StyledInput = styled(InputBase)(({ theme }) => ({
		width: "100%",
		"& input": {
			display: "none"
		},
	}));

    const openTaxPlanOption = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setAnchorEl(null);
	};


    const closeTaxPlan = () => {
        dispatch(userActions.userTaxPlanDialog({
            isOpen: false,
            step: 1,
        }))
	}

    const submitUserTaxPlan =(fields, actions) => {
        let data = new FormData()
        data.append("user_plan_id",  userPlan.user_plan_id )
        data.append("plan_id_for_update", selectTaxPLan.planId)
        if(selectTaxPLan?.defaultValue === "Satoshi"){
            data.append("extra_transaction", Number(fields.extra_transaction))
        }
        REQUEST_POST_UPDATE_TAX_PLAN(data)
            .then((res) => {
                res = getBody(res)
            })
        handleTaxPlan();
        closeTaxPlan();
    }
    
    return (
        <Dialog
			open={openTaxPlan.isOpen}
			onClose={closeTaxPlan}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
                Update User Plan
				<DialogCloseButton
					aria-label="close"
					onClick={closeTaxPlan}
                    size="small"
				>
					<CloseIcon />
				</DialogCloseButton>
			</DialogTitle>
			<DialogContent dividers >
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        tax_plan:  selectTaxPLan?.defaultValue ? selectTaxPLan?.defaultValue : "",
                        extra_transaction: null,

                    }}
                    enableReinitialize={true}
                    onSubmit={(fields, actions) => {
                        submitUserTaxPlan(fields,actions);
                    }}
                >
                    {({ values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} >
                            <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Spacer space={1}></Spacer>
                                    <Typography align="center" variant="h6" > Select New Plan </Typography>
                                    <Spacer space={0.5}></Spacer>
                                    <React.Fragment>
                                        <Box position={"relative"}>
                                            <AutoCompleteTaxPlanBtn
                                                sx={{
                                                    "&.MuiButtonBase-root:hover": {
                                                        backgroundColor: "primary.12p",
                                                    }
                                                }}
                                                endIcon={ open ? <ArrowDropUp style={{fontSize: '24px', right:"12px"}} />: <ArrowDropDown style={{fontSize: '24px', right:"12px"}} />}
                                                onClick={openTaxPlanOption}
                                                fullWidth
                                                aria-describedby={id}
                                                variant="standard"
                                            >
                                                {/* {reasonData.name ?  */}
                                                {console.log(selectTaxPLan?.defaultValue)}
                                                    <Typography sx={{ color: 'text.primary'}} variant="h6">{selectTaxPLan?.defaultValue}</Typography>
                                                {/* } */}
                                            </AutoCompleteTaxPlanBtn>
                                        </Box>
                                        {isOpen && (
                                            <StyledPopper
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                placement="bottom-start"
                                                modifiers={[
                                                    {
                                                    name: "sameWidth",
                                                    enabled: true,
                                                    fn: ({ state }) => {
                                                        state.styles.popper.width = `${state.rects.reference.width}px`;
                                                    },
                                                    phase: "beforeWrite",
                                                    requires: ["computeStyles"],
                                                    effect: ({ state }) => {
                                                        state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`;
                                                    }
                                                    }
                                                ]}
                                            >
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <div>
                                                        <Autocomplete
                                                            open
                                                            options={planList}
                                                            disableCloseOnSelect
                                                            name="tax_plan"
                                                            PopperComponent={PopperComponent}
                                                            isOptionEqualToValue={(option, value) => {
                                                                console.log("option======>", option)
                                                                console.log("value======>", value)
                                                                return option.name === value || value === "";
                                                            }}
                                                            getOptionLabel={(option) => option.name ? option.name : ""}
                                                            value={selectTaxPLan?.defaultValue}
                                                            onChange={(event, value) => {
                                                                setFieldValue("tax_plan", value)
                                                                handleTaxPlanSelect(value)}
                                                            }
                                                            renderOption={(props, option) => (
                                                                <>
                                                                    <Grid container justifyContent="left" alignItems="center" wrap="nowrap" sx={{ borderRadius: 0,  cursor: 'pointer', backgroundColor:'primary.8p'}} {...props} key={option.key}> 
                                                                        <Grid item xs={12} sx={{ py: 0, position: 'relative' }}>
                                                                            {option.name}
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            sx={{
                                                                display: 'flex'
                                                            }}
                                                            componentsProps={{
                                                                popper: {
                                                                sx: {
                                                                    '& .MuiAutocomplete-listbox' : { py: 0 }
                                                                }
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <StyledInput
                                                                    ref={params.InputProps.ref}
                                                                    inputProps={params.inputProps}
                                                                    autoFocus
                                                                />
                                                            )}
                                                            onClose={(event, reason) => {
                                                                if (reason === "escape") {
                                                                    handleClose();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </ClickAwayListener>
                                            </StyledPopper>
                                        )}
                                    </React.Fragment>
                                </Grid>
                                {
                                    selectTaxPLan?.defaultValue === "Satoshi" &&
                                        <Grid item xs={7} sm={7} md={7}>
                                            <Spacer space={4}></Spacer>
                                            <TextField
                                                disabled={true}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                label="Extra Transaction Limit"
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={userPlan?.extra_transactions}
                                            />
                                            <Spacer space={2}></Spacer>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                label="New Extra Transaction Limit"
                                                name="extra_transaction"
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values?.extra_transaction}
                                            />
                                        </Grid>
                                }
                            </Grid>
                            <Box textAlign="center">
                                <Button type="submit" variant="text" color="secondary" size="medium">Update</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
		</Dialog>
    )
}

export default UserTaxPlan