import React, { useEffect, useState } from "react";
import { Divider, InputAdornment, Pagination, Slide, TextField, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { REQUEST_POST_ALL_ACCOUNTANTS } from "../../../_services/users";
import { getBody } from "../../../_services";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
	Typography,
	Card,
	Skeleton,
	Table,
	TableHead,
	TableRow,
	TableCell,
	CardContent,
	TableBody,
	Stack,
	Grid,
	TableContainer,
    IconButton,
    Box
} from "@mui/material";
import { userActions } from "../../../_actions/user.table.actions";
import { useDispatch } from "react-redux";
import { chunk } from 'lodash'
import AllAccountantData from "./AllAccountantData";
import Spacer from "../Spacer/Spacer";

const AllAccountants = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
	const containerRef = React.useRef(null);;
    const dispatch = useDispatch();	
	const [loading, setLoading] = useState(true)
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [accountantData, setAccountantData] = useState([]);
    const [totalPages, setTotalPage] = useState(1);
    const [checked, setChecked] = useState(false);
	const [searchText, setSearchText] = useState("");

    const [isUpdate, setIsUpdate] = useState(false);
	const handleAllAccountantTbl = () => {
		setIsUpdate(!isUpdate);
	}

    const handleChange = () => {
		if (checked) {
			setSearchText("");
		}
		setChecked((prev) => !prev);
	};

    const handleSearch = (prop) => (event) => {
		const searchParameter = event.target.value;
		setSearchText(searchParameter)
        REQUEST_POST_ALL_ACCOUNTANTS({page: 1, limit:10, searchTerm: searchParameter})
            .then((res) => {
            res = getBody(res);
            const getResData = res.data;
            if(res.code === 200){
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setAccountantData(getResData?.accountant_data);
                setLoading(false);
            }
        })

        const allAccountantRow = accountantData
			.filter((data) => {
					return data
			})
			.filter((data) => {
				return data.name.toLowerCase().includes(searchParameter.toLowerCase());
			})
			setTotalPage(chunk(allAccountantRow, pageSize))
			setAccountantData(chunk(allAccountantRow, pageSize).length > 0 ? chunk(allAccountantRow, pageSize)[0] : [])
	};

    useEffect(() => {
        REQUEST_POST_ALL_ACCOUNTANTS({page: 1, limit:10})
        .then((res) => {
            res = getBody(res);
            const getResData = res.data;
            if(res.code === 200){
                dispatch(userActions.allAccountantsTbl(getResData?.accountant_data));
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setAccountantData(getResData?.accountant_data);
                setLoading(false);
            }
        })
    }, [checked, isUpdate])

    const paginationHandle = (event, value) => {
        setLoading(true);
        setPage(value);
        REQUEST_POST_ALL_ACCOUNTANTS({page:value, limit:10})
        .then((res) =>{
            res = getBody(res);
            if(res.code === 200){
                dispatch(userActions.allAccountantsTbl(res.data.accountant_data));
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setAccountantData(res?.data?.accountant_data)
                setLoading(false);
            }
        })
    };

	return (
        <Box m="10px" mb={5}>
            <Box>
                <Typography variant="h4" sx={{ letterSpacing:2 }}> ALL ACCOUNTANTS </Typography>
                <Divider></Divider>
            </Box>
            <Spacer space={3}/>
            <Grid container sx={{ mb: 1 }}>
                <Grid item xs={3.5} ref={containerRef}>
                    <Box sx={{ display:{xs:'flex', sm:'flex', md:'flex'} }}>
                        <Slide
                            direction="left"
                            in={checked}
                            container={containerRef.current}
                        >
                            <TextField 
                                id="search" 
                                fullWidth 
                                value={searchText}
                                onChange={handleSearch("search")}
                                onBlur={!containerRef.current}
                                autoFocus
                                variant="standard" 
                                autoComplete="off" 
                                placeholder={"search"} 
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ mr:1 }}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ p: 0.1 }}
                                                onClick={handleChange}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}/>
                        </Slide>
                        <Box sx={{ display:{xs:'flex',sm:'flex', md:'flex'}, position:"absolute"}}>
                            {!checked && (
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={handleChange}
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Card variant="standard" sx={{ padding: 0}} >
                <CardContent >
                    <TableContainer>
                        <Table aria-label="collapsible table" sx={{ border: 'none', boxShadow: 0,  maxHeight: "300px"}}>
                            <TableHead sx={{ whiteSpace: 'nowrap', backgroundColor:colors.blueAccent[700] }} >
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pr: 2}}
                                    >
                                        <Typography variant="toolTip">
                                            ID
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0,}}
                                    >
                                        <Typography variant="toolTip">
                                            Accountant
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pl: 2, pr: 2}}
                                    >
                                        <Typography variant="toolTip">
                                            Country
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Clients
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Actions
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {loading &&
                                    [...Array(10)].map((x, i) => (
                                        <TableRow key={i}>
                                            <TableCell sx={{ width: '15%' , py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"75%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"75%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '18%', pl:1.5, py:1.3 }}>
                                                <Stack direction={"row"} alignItems={'left'}>
                                                    <Skeleton
                                                        animation="wave"
                                                        width="35%"
                                                        height={30}
                                                        sx={{
                                                            marginLeft: "5px",
                                                        }}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"70%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"75%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                ))}

                                {!loading && accountantData.length > 0 &&
                                    <>
                                        {Object.values(accountantData).map((data, i) => {
                                            return(
                                                <>
                                                    {
                                                        data && <AllAccountantData data={data} key={i} deleteAccountantUser={handleAllAccountantTbl} />
                                                    }
                                                </>
                                        )})}
                                    </>
                                }
                            </TableBody>
                        </Table>
                        
                        <Grid container justifyContent={'left'} sx={{padding:'16px 6px 32px 6px'}}>
                            {loading && 
                                <Box>
                                    <Stack ml={1} direction={"row"} gap={1} alignItems={'center'}>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                        <Skeleton variant="circular" animation="wave" height="30px" width="30px"/>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                    </Stack>
                                </Box>
                            }
                            {!loading && accountantData.length > 0 &&
                                <Pagination
                                    color="secondary"
                                    count={totalPages}
                                    page={page}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    onChange={paginationHandle}
                                />
                            }
                        </Grid>

                        {!loading && accountantData.length <= 0  &&
                            <Grid container textAlign={'center'}>
                                <Grid item xs={12}>
                                    <Spacer space={8}/>
                                    <Typography variant='h4'> All Accountants data not found </Typography>
                                    <Spacer space={11}/>
                                </Grid>
                            </Grid>
                        }
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>



    )
};

export default AllAccountants;
