import React, { useEffect, useState } from "react";
import { Divider, InputAdornment, Pagination, Slide, TextField, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { POST_USER_TAXREPORT_LIST } from "../../../_services/users";
import { getBody } from "../../../_services";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
	Typography,
	Card,
	Skeleton,
	Table,
	TableHead,
	TableRow,
	TableCell,
	CardContent,
	TableBody,
	Stack,
	Grid,
	TableContainer,
    IconButton,
    Box
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Spacer from "../Spacer/Spacer";
import { useParams } from 'react-router-dom';
import GenerateTaxReportData from "./GenerateTaxReportData";
import TaxReportListAutocomplate from "../CustomCode/TaxReportListAutocomplate";

const GenerateTaxReport = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
    const RequestByList = [
		{
			name: "All"
		},{
			name: "User Request",
			isSelected: false,
			key: "User",
		},{
			name: "Admin Request",
			isSelected: false,
			key: "Admin",
		},{
			name: "Tax Accountant Request",
			isSelected: false,
			key: "Tax Accountant",
		}
	];
	const containerRef = React.useRef(null);
    const params = useParams()
    const dispatch = useDispatch();	
	const [loading, setLoading] = useState(true)
    const taxReportListFilter = useSelector((state) => state.taxReportListFilter);
    const isUpdate = useSelector((state) => state.updateDeletedUsers);
    const pageSize = 5;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPage] = useState(0);
    const [checked, setChecked] = useState(false);
	const [searchText, setSearchText] = useState("");
    const [taxReportList, setTaxReportList] = useState([]);

    const handleChange = () => {
        if (checked) {
            setSearchText("");
        }
        setChecked((prev) => !prev);
    };

    useEffect(() => {
		getTaxReportList();
	}, [taxReportListFilter, isUpdate.count]);
    
    const getTaxReportList = () => {
        let formData = { user_id: params.id, page: 1, limit:5, searchTerm:searchText, requested_from: taxReportListFilter.requested_from};

		POST_USER_TAXREPORT_LIST(formData)
        .then((res) => {
            res = getBody(res);
            const getResData = res?.data;
            if(res.code === 200){
                setTaxReportList(getResData?.tax_report_list);
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setLoading(false);
            }
        }).catch((error) => 
            console.log(error)
        );
	};

    useEffect(() => {
        POST_USER_TAXREPORT_LIST({user_id: params.id, page: 1, limit:5, date: "desc"})
        .then((res) => {
            res = getBody(res);
            const getResData = res?.data;
            if(res.code === 200){
                setTaxReportList(getResData?.tax_report_list);
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setLoading(false);
            }
        })
    }, [checked])

    const paginationHandle = (event, value) => {
        setLoading(true);
        setPage(value);
        POST_USER_TAXREPORT_LIST({user_id: params.id, page: value, limit:5})
        .then((res) => {
            res = getBody(res);
            const getResData = res?.data;
            if(res.code === 200){
                setTaxReportList(getResData?.tax_report_list);
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setLoading(false);
            }
        })
    };

	return ( 
        <Box m="10px" mb={5}>
            <Box>
                <Typography variant="h4" sx={{ letterSpacing:2 }}> TAX REPORTS </Typography>
                <Divider></Divider>
            </Box>
            <Spacer space={3}/>
            <Grid container direction="row" justifyContent="space-between" alignContent={'center'} mb={1}>
                <Grid item sm={4} xs={4} md={4} textAlign={'left'}>
                    <TaxReportListAutocomplate
                        name={"requestedby"}
                        placeholder={"All"}
                        label=""
                        isCloseIcon={true}
                        isFilter={true}
                        type="requestedby"
                        optionData={RequestByList}
                        selectedVal={RequestByList.filter(row => row.key === taxReportListFilter.requested_from).map(filteredRow => filteredRow)[0]}
                    >
                    </TaxReportListAutocomplate>
                </Grid>

                <Grid item ref={containerRef} sm={4} xs={4} md={4} textAlign={'left'}>
                    <Box sx={{ display:{xs:'flex', sm:'flex', md:'flex'},  pt: checked ? "6px" : "11px" }}  >
                        <Slide
                            direction="right"
                            in={checked}
                            container={containerRef.current}
                        >
                            <TextField 
                                id="search"
                                name="search"
                                fullWidth
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                    getTaxReportList("search", e.target.value)
                                }}
                                onBlur={!containerRef.current}
                                autoFocus
                                variant="standard"
                                autoComplete="off"
                                placeholder={"search"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ mr:1 }}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ p: 0.1 }}
                                                onClick={handleChange}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}/>
                        </Slide>
                        <Box sx={{ display:{xs:'flex',sm:'flex', md:'flex'}}}>
                            {!checked && (
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={handleChange}
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Card variant="standard" sx={{ padding: 0}} >
                <CardContent >
                    <TableContainer>
                        <Table aria-label="collapsible table" sx={{ border: 'none', boxShadow: 0,  maxHeight: "300px"}}>
                            <TableHead sx={{ whiteSpace: 'nowrap', backgroundColor:colors.blueAccent[700] }} >
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pr: 2}}
                                    >
                                        <Typography variant="toolTip">
                                            Date
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0,}}
                                    >
                                        <Typography variant="toolTip">
                                            User
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pl: 2, pr: 2}}
                                    >
                                        <Typography variant="toolTip">
                                            Tax Report
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Tax Year
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                           IP
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Requested By
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:3 }}
                                    >
                                        <Typography variant="toolTip">
                                            Action
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading &&
                                    [...Array(10)].map((x, i) => (
                                        <TableRow key={i}>
                                            <TableCell sx={{ width: '10%' , pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '20%', pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '5%', pl:1.5, py:1.3 }}>
                                                <Stack direction={"row"} alignItems={'left'}>
                                                    <Skeleton
                                                        animation="wave"
                                                        width="100%"
                                                        height={30}
                                                        sx={{
                                                            marginLeft: "5px",
                                                        }}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"70%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"75%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"70%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={{ width: '10%', pr:2, py:1.3 }}>
                                                <Stack alignItems={"flex-start"}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={30}
                                                        width={"100%"}
                                                    />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                ))}

                                {!loading && taxReportList.length > 0 &&
                                    <>
                                        {Object.values(taxReportList).map((data, i) => {
                                            return(
                                                <>
                                                    {
                                                        data && <GenerateTaxReportData data={data} key={i}/>
                                                    }
                                                </>
                                            )
                                        })}
                                    </>
                                }
                            </TableBody>
                        </Table>
                        <Grid container justifyContent={'left'} sx={{padding:'16px 6px 32px 6px'}}>
                            {loading &&
                                <Box>
                                    <Stack ml={1} direction={"row"} gap={1} alignItems={'center'}>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                        <Skeleton variant="circular" animation="wave" height="30px" width="30px"/>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                    </Stack>
                                </Box>
                            }
                            {!loading && taxReportList.length > 0 &&
                                <Pagination
                                    color="secondary"
                                    count={totalPages}
                                    page={page}
                                    onChange={paginationHandle}
                                />
                            }
                        </Grid>
                        {!loading && taxReportList.length <= 0  &&
                            <Grid container textAlign={'center'}>
                                <Grid item xs={12}>
                                    <Spacer space={8}/>
                                    <Typography variant='h4'> Tax report data not found </Typography>
                                    <Spacer space={11}/>
                                </Grid>
                            </Grid>
                        }
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
};

export default GenerateTaxReport;
