import axios from "axios";
import { loginActions } from '../_actions/login.actions';
import {store} from '../_helpers/store'

const API_URL = process.env.REACT_APP_API_URL;

export const GET = (route, params = {}) => {

    let token = localStorage.getItem("access_token");
    let headers = {
		'Authorization': `Bearer ${token}`,
		'device-type': 'web',
		...((token) && { 'access-token': token,
		}),
	}
    return axios.get(route, { params, headers });

}

// export const POST = (route, params = {}, stoppable = false) => {

//     let token = localStorage.getItem('access_token');

//     if([`${API_URL}/login`].includes(route)){
//         const headers = {
// 			'device-type': 'web',
// 		}
// 		return axios.post(route, params, { headers: headers });
//     }else{
//         let headers = {
// 			'Authorization': `Bearer ${token}`,
// 			'device-type': 'web', ...((token) && { 'access-token': token,})
// 		}
//         return axios.post(route, params, { headers: headers });
//     }
// }

export const POST = (route, params = {}) => {

	let token = localStorage.getItem('access_token');
	if ([`${API_URL}/login`, `${API_URL}/register`, `${API_URL}/login/google`, `${API_URL}/forgot-passwords`,].includes(route)) {
		const headers = {
			'device-type': 'web',
		}
		return axios.post(route, params, { headers: headers });
	} else {
		let headers = {
			'Authorization': `Bearer ${token}`,
			'device-type': 'web',
			...((token) && { 'access-token': token,
			}),
			"show-details": true,
		}
		return axios.post(route, params, { headers: headers });
}}

export const getBody = (response) => {
	// if (response.response.status === 503) {
	// 	window.location.href = '/maintenance';
	// } else {
		if (response.message && response.message === "Network Error") {
			
		} else {
			if (response.data && response.data.code && response.data.code === 412) {
				store.dispatch(loginActions.userLogout());
				localStorage.removeItem('user');
				localStorage.removeItem('access_token');
				window.top.close();
			}
			if ("response" in response && response.response.data.code === 401) {
				window.location.href = "/login"
				localStorage.clear()
			}
			if (response.data && response.data.meta) {
				store.dispatch(loginActions.setMetaData(response.data.meta));
				// localStorage.setItem("meta_data", JSON.stringify(response.data.meta))
			}
			return response.data;
		}
	// }
};