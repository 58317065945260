import React from 'react'
import { Box, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import OutboundIcon from '@mui/icons-material/Outbound';
import { getBody } from '../../../_services';
import { POST_ACCOUNT_LOGIN } from '../../../_services/users';
import { userActions } from '../../../_actions';
import { useDispatch } from 'react-redux';
import DeleteUserAccount from './Dialogs/DeleteUserAccount';

const AllAccountantData = (props) => {
    const data = props?.data;
	const dispatch = useDispatch();
    const REACT_LIVE_APP_URL = process.env.REACT_APP_LIVE_URL;

    const accountantLogin = (accountantID) => {

        POST_ACCOUNT_LOGIN({accountant_id: accountantID})
        .then((res) =>{
            res = getBody(res);
            if(res.code === 200){

                var receiverWindow = window.open(REACT_LIVE_APP_URL + "/team", '_blank');
                const receiverIframe = document.getElementById("receiver-iframe");
                receiverIframe.contentWindow.postMessage(res.data, REACT_LIVE_APP_URL + "/team");

                // Wait for the receiver window to load and then post the message
                receiverWindow.addEventListener('load', function() {
                    console.log(res.data);
                });
            }
        })
    }

    const openDeleteAccountDialog = (accountantId) => {
        dispatch(userActions.deleteUserAccountDialog({
            isOpen: true,
            step: 1,
            accountant_id: accountantId
        }))
    }

    return (
        <>
            <React.Fragment key={props.key}>
                <TableRow>
                    <TableCell
                        align={"left"}
                        sx={{ py: 1, width:"0%"}}
                    >
                        {data?.id}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1,pr:0, width:"2%"}}
                    >
                        {data?.name}
                        <Typography variant="subtitle2" color={"secondary"} > {data?.email}</Typography>
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1,pr:0, width:"2%"}}
                    >
                        {data?.home_country}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1, pr:0, width:"2%"}}
                    >
                        {data?.share_account_details_count}
                    </TableCell>

                    <TableCell
                        align={"left"}
                        sx={{ py: 1, width:"1%"}}
                    >
                        <Box>
                            <Stack direction="row">
                                <Tooltip title="Login Accountant" arrow>
                                    <IconButton onClick={() => accountantLogin(data?.id)} sx={{ color: "#f9a825" }} size="small">
                                        <OutboundIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete Accountant" arrow>
                                    <IconButton onClick={() => openDeleteAccountDialog(data?.id)} color="error" size="small">
                                        <DeleteIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    </TableCell>
                </TableRow>
                <Grid sx={{"visibility" : "hidden", "height" : 0, "width": "0"}}>
                    <iframe
                        id="receiver-iframe"
                        src={REACT_LIVE_APP_URL + "/team"}
                    />
                </Grid>
            </React.Fragment>
            <DeleteUserAccount deleteAccountantUser={props.deleteAccountantUser}></DeleteUserAccount>
        </>
    )
}

export default AllAccountantData