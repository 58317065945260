import { userConstants } from "../_constants";

const filterState = {
	reason: null,
	per_page: 10,
	page: 1,
	txType: null,
	txLable: null,
	txSellCurr: null,
	txBuyCurr: null,
	txExchange: null,
	requested_from: null,
}

const initialState = [];


// All-Users

export const allUserPagination = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.ALLUSER_PAGINATION:
			return action.data;
		default:
			return state;
	}
};

export const allUserFilter = ( state = filterState, action) => {
	switch (action.type) {
		case userConstants.ALLUSER_FILTER:
			return action.data;
		case userConstants.REMOVE_ALLUSER_FILTER:
			return filterState;
		default:
			return state;
	}
};

export const editUserDetails = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.EDIT_USER_DETAILS:
			return action.data;	
		default:
			return state;
	}
}

export const apiLimitBlockchainDialog = (state = {isOpen: false, step: 1}, action) => {
	switch (action.type) {
		case userConstants.API_LIMIT_BLOCKCHAIN_DIALOG:
			return action.details	
		default:
			return state;
	}
};

export const userTaxPlanDialog = (state = {isOpen: false, step: 1, name: ""}, action) => {
	switch (action.type) {
		case userConstants.USER_TAX_PLAN_DIALOG:
			return action.details	
		default:
			return state;
	}
};

export const userViewTransaction = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.USER_VIEW_TRANSACTION:
			return action.data;
		default:
			return state;
	}
};

export const userTxnFilter = ( state = filterState, action) => {
	switch (action.type) {
		case userConstants.USER_VIEW_TXN_FILTER:
			return action.data;
		case userConstants.REMOVE_VIEW_TXN_FILTER:
			return filterState;
		default:
			return state;
	}
};

export const downloadTaxFormDialog = (state = {isOpen: false, step: 1, user_id: null}, action) => {
	switch (action.type) {
		case userConstants.DOWNLOAD_TAX_FORMS_DIALOG:
			return action.details	
		default:
			return state;
	}
};

export const taxReportListFilter = ( state = filterState, action) => {
	switch (action.type) {
		case userConstants.TAX_REPORT_LIST_FILTER:
			return action.data;
		case userConstants.REMOVE_TAX_REPORT_LIST_FILTER:
			return filterState;
		default:
			return state;
	}
};

export const deleteUserAccountDialog = (state = {isOpen: false, step: 1, user_id: null, accountant_id: null }, action) => {
	switch (action.type) {
		case userConstants.DELETE_USER_ACCOUNT:
			return action.details	
		default:
			return state;
	}
};



// All-accountants

export const allAccountantsTbl = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.ALL_ACCOUNTANTS:
			return action.data;
		default:
			return state;
	}
};


// Admin-Users

export const adminUsersTbl = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.ADMIN_USERS:
			return action.data;
		default:
			return state;
	}
};


// Unverified-Users

export const unverifiedUsersTbl = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.UNVERIFIED_USERS:
			return action.data;
		default:
			return state;
	}
};


// Tax-Reports

export const taxReportTbl = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.TAX_REPORT_TABLE:
			return action.data;
		default:
			return state;
	}
};


// Deleted-Users

export const deletedUsersTbl = ( state = initialState, action) => {
	switch (action.type) {
		case userConstants.DELETED_USER_TABLE:
			return action.data;
		default:
			return state;
	}
};

export const deleteUsersFilter = ( state = filterState, action) => {
	switch (action.type) {
		case userConstants.DELETED_USER_FILTER:
			return action.data;
		case userConstants.REMOVE_DELETED_USER_FILTER:
			return filterState;
		default:
			return state;
	}
};

export const updateDeletedUsers = (state = { count: 0 }, action) => {
	switch (action.type) {
		case  userConstants.UPDATE_DELETED_USERS:
			return action.details;
		default:
			return state;
	}
};


// Pending-Deleted-Users

export const pendingDelUsers = (state = initialState, action) => {
	switch (action.type) {
		case userConstants.UPDATE_PENDING_DELETED_USERS:
			return action.type;
		default:
			return state;
	}
}


// Partner-Users

export const partnerUsers = (state = initialState, action) => {
	switch (action.type) {
		case userConstants.UPDATE_PENDING_DELETED_USERS:
			return action.type;
		default:
			return state;
	}
}
