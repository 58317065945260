import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React from 'react'
import { DialogCloseButton } from '../../CustomDesignComponent'
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../_actions';
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from 'formik';
import Spacer from '../../Spacer/Spacer';
import { REQUEST_POST_UPDATE_BLOCKCHAIN_LIMIT } from '../../../../_services/users';
import { getBody } from '../../../../_services';
import { useRef } from 'react';

const ApiLimitBlockchain = (props) => {

    const formRef = useRef();
    const openApiLimitBlockchain = useSelector((state) => state.apiLimitBlockchainDialog);
	const dispatch = useDispatch();
    let { blockchainLimit , id } = props;

    const closeApiLimitBlockchain = () => {
		dispatch(
			dispatch(
                userActions.apiLimitBlockchainDialog({
                    isOpen: false,
                    step: 1,
                })
            )
		);
	}

    const handleBlockchainLimit = () => {
		props.handleApiBlcLimit();
	}

    const submitBlockchainLimit =(fields, actions) => {
        let data = new FormData()
        data.append("user_id", id )
        data.append("eth_bsc_limit", fields.eth_bsc_limit )
        data.append("blockchain_limit", fields.blockchain_limit)

        REQUEST_POST_UPDATE_BLOCKCHAIN_LIMIT(data)
            .then((res) => {
                res = getBody(res)
                if(res.code === 200){
                    handleBlockchainLimit();
                }
            })
            closeApiLimitBlockchain();
    }

    return (
        <Dialog
			open={openApiLimitBlockchain.isOpen}
			onClose={closeApiLimitBlockchain}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>
                Api Limit Blockchain
				<DialogCloseButton
					aria-label="close"
					onClick={closeApiLimitBlockchain}
                    size="small"
				>
					<CloseIcon />
				</DialogCloseButton>
			</DialogTitle>
			<DialogContent dividers >
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        eth_bsc_limit: blockchainLimit?.eth_bsc_limit ? blockchainLimit?.eth_bsc_limit : "null",
                        blockchain_limit: blockchainLimit?.blockchain_limit ? blockchainLimit?.blockchain_limit : "null",
                    }}
                    enableReinitialize={true}
                    onSubmit={(fields, actions) => {
                        submitBlockchainLimit(fields,actions);
                    }}
                >
                    {({ values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} >
                            <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: 2 }}>
                                <Grid item xs={7} sm={7} md={7}>
                                    <TextField
                                        fullWidth
                                        id="filled-hidden-label-small"
                                        variant="standard"
                                        size="small"
                                        label="Etherscan APIs"
                                        name="eth_bsc_limit"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.eth_bsc_limit}
                                    />
                                    <Spacer space={2}></Spacer>
                                    <TextField
                                        fullWidth
                                        id="filled-hidden-label-small"
                                        variant="standard"
                                        size="small"
                                        label="Other blockchains"
                                        name="blockchain_limit"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values?.blockchain_limit}
                                    />
                                </Grid>
                            </Grid>
                            <Box textAlign="center">
                                <Button type="submit" variant="text" color="secondary" size="small">Update</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
		</Dialog>
    )
}

export default ApiLimitBlockchain