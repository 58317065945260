import { GET, POST } from "."
import { ROUTE_ACCOUNT_LOGIN, ROUTE_ADMIN_USERS, ROUTE_ALL_ACCOUNTANTS, ROUTE_ALL_USERS, ROUTE_DELETED_USERS, ROUTE_DELETE_ACCOUNTANT_USER, ROUTE_DELETE_USER_ACCOUNT, ROUTE_DOWNLOAD_TAXREPORT, ROUTE_DOWNLOAD_USER_REPORT, ROUTE_EDIT_ADMIN_USER, ROUTE_PARTNER_USERS, ROUTE_PENDING_DELETED_USERS, ROUTE_TAX_REPORTS, ROUTE_UNVERIFIED_USERS, ROUTE_UPDATE_BLOCKCHAIN_LIMIT, ROUTE_UPDATE_USER_TAX_PLAN, ROUTE_USER_DETAILS, ROUTE_USER_DETAILS_UPDATE, ROUTE_USER_LOGIN, ROUTE_USER_REPORT_LIST, ROUTE_VIEW_USER_TRANSACTION } from "../dist/api"

export const REQUEST_POST_ALL_USERS = (formData) => {
    return POST(ROUTE_ALL_USERS, formData);
}

export const REQUEST_POST_ALL_ACCOUNTANTS = (formData) => {
    return POST(ROUTE_ALL_ACCOUNTANTS, formData);
}

export const REQUEST_POST_ADMIN_USERS = (formData) => {
    return POST(ROUTE_ADMIN_USERS, formData);
}

export const REQUEST_POST_UNVERIFIED_USERS = (formData) => {
    return POST(ROUTE_UNVERIFIED_USERS, formData);
}

export const REQUEST_POST_TAX_REPORTS = (formData) => {
    return POST(ROUTE_TAX_REPORTS, formData);
}

export const REQUEST_POST_DELETED_USERS = (formData) => {
    return POST(ROUTE_DELETED_USERS, formData);
}

export const REQUEST_POST_PENDING_DELETED_USERS = (formData) => {
    return POST(ROUTE_PENDING_DELETED_USERS, formData);
}

export const REQUEST_POST_PARTNER_USERS = (formData) => {
    return POST(ROUTE_PARTNER_USERS, formData);
}

export const REQUEST_POST_USER_DETAILS = (formData) => {
    return POST(ROUTE_USER_DETAILS, formData);
}

export const REQUEST_POST_USER_DETAILS_UPDATE = (formData) => {
    return POST(ROUTE_USER_DETAILS_UPDATE, formData);
}

export const REQUEST_POST_UPDATE_BLOCKCHAIN_LIMIT = (formData) => {
    return POST(ROUTE_UPDATE_BLOCKCHAIN_LIMIT, formData);
}

export const REQUEST_POST_UPDATE_TAX_PLAN = (formData) => {
    return POST(ROUTE_UPDATE_USER_TAX_PLAN, formData);
}

export const POST_VIEW_USER_TRANSACTION = (formData) => {
    return POST(ROUTE_VIEW_USER_TRANSACTION, formData);
}

export const POST_USER_LOGIN = (formData) => {
    return POST(ROUTE_USER_LOGIN, formData);
}

export const POST_DOWNLOAD_TAXREPORT= (formData) => {
    return POST(ROUTE_DOWNLOAD_TAXREPORT, formData);
}

export const POST_USER_TAXREPORT_LIST= (formData) => {
    return POST(ROUTE_USER_REPORT_LIST, formData);
}

export const POST_DOWNLOAD_USER_REPORT = (formData) => {
    return POST(ROUTE_DOWNLOAD_USER_REPORT, formData);
}

export const POST_DELETE_USER_ACCOUNT = (formData) => {
    return POST(ROUTE_DELETE_USER_ACCOUNT, formData);
}

export const POST_ACCOUNT_LOGIN = (formData) => {
    return POST(ROUTE_ACCOUNT_LOGIN, formData);
}

export const POST_DELETE_ACCOUNTANT_USER = (formData) => {
    return POST(ROUTE_DELETE_ACCOUNTANT_USER, formData);
}

export const POST_EDIT_ADMIN_USER = (formData) => {
    return POST(ROUTE_EDIT_ADMIN_USER, formData);
}
