import React, { useEffect, useState } from "react";
import { Divider, InputAdornment, Pagination, Slide, TextField, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { REQUEST_POST_DELETED_USERS } from "../../../_services/users";
import { getBody } from "../../../_services";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
	Typography,
	Card,
	Skeleton,
	Table,
	TableHead,
	TableRow,
	TableCell,
	CardContent,
	TableBody,
	Stack,
	Grid,
	TableContainer,
    IconButton,
    Box
} from "@mui/material";
import { userActions } from "../../../_actions/user.table.actions";
import DeletedUserData from "./DeletedUserData";
import DeleteReasonAutocomplete from "../CustomCode/DeleteReasonAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import Spacer from "../Spacer/Spacer";

const DeletedUsers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const reason = [
		{
			name: "Could not import all transactions",
			isSelected: false,
			key: "import_all_transactions",
		},
		{
			name: "I had another issue",
			isSelected: false,
			key: "another_issue",
		},
		{
			name: "Switched to a different solution",
			isSelected: false,
			key: "different_solution",
		},
		{
			name: "I don't need a tool for my crypto taxes",
			isSelected: false,
			key: "my_crypto_taxes",
		},
		{
			name: "Other",
			isSelected: false,
			key: "other",
		}
	];

    const containerRef = React.useRef(null);
    const dispatch = useDispatch();	
	const [loading, setLoading] = useState(true)
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPage] = useState(1);
    const [deletedUserData, setDeletedUserData] = useState([]);
    const deleteUsersFilter = useSelector((state) => state.deleteUsersFilter);
    const isUpdate = useSelector((state) => state.updateDeletedUsers);
    const [checked, setChecked] = useState(false);
	const [searchText, setSearchText] = useState("");

    const handleChange = () => {
		if (checked) {
			setSearchText("");
		}
		setChecked((prev) => !prev);
	};

    useEffect(() => {
		getDeletedUsersData("reason");
	}, [deleteUsersFilter, isUpdate.count]);

    const getDeletedUsersData = () => {

        let formData = {page: 1, limit:10, searchTerm:searchText, reason: deleteUsersFilter.reason};
		
		REQUEST_POST_DELETED_USERS(formData)
        .then((res) => {
            res = getBody(res);
            if(res.code === 200){
                dispatch(userActions.deletedUsersTbl(res.data.deletedUserList));
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setDeletedUserData(res.data?.deletedUserList);
                setLoading(false);
            }
        })
        .catch((error) => 
            console.log(error)
        );
	};

    useEffect(() => {
        REQUEST_POST_DELETED_USERS({page: 1, limit:10})
        .then((res) => {
            res = getBody(res);
            if(res.code === 200){
                dispatch(userActions.deletedUsersTbl(res.data.deletedUserList));
                setTotalPage(Math.ceil(res.data.totalCount / pageSize))
                setDeletedUserData(res?.data?.deletedUserList);
                setLoading(false);
            }
        })
    }, [checked])

    const paginationHandle = (event, value) => {
        setLoading(true);
        setPage(value);
        REQUEST_POST_DELETED_USERS({page:value, limit:10})
        .then((res) =>{
            res = getBody(res);
            if(res.code === 200){
                dispatch(userActions.deletedUsersTbl(res?.data?.deletedUserList));
                setTotalPage(Math.ceil(res?.data?.totalCount / pageSize))
                setDeletedUserData(res?.data?.deletedUserList);
                setLoading(false);
            }
        })
    };

    return (
        <Box m="10px" mb={5}>
            <Box>
                <Typography variant="h4" sx={{ letterSpacing:2 }}> DELETED USERS </Typography>
                <Divider></Divider>
            </Box>
            <Spacer space={3}/>
            <Grid container direction="row" justifyContent="space-between" alignContent={'center'} mb={1}>
                <Grid item sm={4} xs={4} md={4} textAlign={'left'}>
                    <DeleteReasonAutocomplete
                        name={"reasons"}
                        placeholder={"Reasons"}
                        label=""
                        isCloseIcon={true}
                        isFilter={true}
                        type="reason"
                        optionData={reason}
                        selectedVal={reason.filter(row => row.key === deleteUsersFilter.reason).map(filteredRow => filteredRow)[0]}
                    >
                    </DeleteReasonAutocomplete>
                </Grid>

                <Grid item sm={4} xs={4} md={4} alignItems={"center"} ref={containerRef}>
                    <Box sx={{ display:{xs:'flex', sm:'flex', md:'flex'},  pt: checked ? "6px" : "11px" }}  >
                        <Slide
                            direction="right"
                            in={checked}
                            container={containerRef.current}
                        >
                            <TextField 
                                id="search"
                                fullWidth
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                    getDeletedUsersData("search", e.target.value)
                                }}
                                onBlur={!containerRef.current}
                                autoFocus
                                variant="standard"
                                autoComplete="off"
                                placeholder={"search"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ mr:1 }}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ p: 0.1 }}
                                                onClick={handleChange}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}/>
                        </Slide>
                        <Box sx={{ display:{xs:'flex',sm:'flex', md:'flex'}}}>
                            {!checked && (
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={handleChange}
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Card variant="standard" sx={{ padding: 0}} >
                <CardContent >
                    <TableContainer>
                        <Table aria-label="collapsible table" sx={{ border: 'none', boxShadow: 0,  maxHeight: "300px"}}>
                            <TableHead sx={{ whiteSpace: 'nowrap', backgroundColor:colors.blueAccent[700] }} >
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pr: 2}}
                                    >
                                        <Typography variant="toolTip">
                                            User
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0,}}
                                    >
                                        <Typography variant="toolTip">
                                            Country
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', pl: 2, pr: 2}}
                                    >                           
                                        <Typography variant="toolTip">
                                            Tax plan
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Deleted At
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Reason
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, paddingRight: 0}}
                                    >
                                        <Typography variant="toolTip">
                                            Feedback
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ border: 'none', paddingLeft: 2, pr:3 }}
                                    >
                                        <Typography variant="toolTip">
                                            Action
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {loading &&
                                [...Array(10)].map((x, i) => (
                                    <TableRow key={i}>
                                        <TableCell sx={{ width: '15%' , py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"75%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', pr:0, py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"75%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '18%', pl:1.5, py:1.3 }}>
                                            <Stack direction={"row"} alignItems={'left'}>
                                                <Skeleton
                                                    animation="wave"
                                                    width="25%"
                                                    height={30}
                                                    sx={{
                                                        marginLeft: "5px",
                                                    }}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"70%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"75%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '9%', pr:0, py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"55%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', pr:3, py:1.3 }}>
                                            <Stack alignItems={"flex-start"}>
                                                <Skeleton
                                                    animation="wave"
                                                    height={30}
                                                    width={"50%"}
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                            ))}

                            {!loading && deletedUserData.length > 0 &&
                                <TableBody>
                                    {Object.values(deletedUserData).map((data, i) => {
                                        return(
                                            <>
                                                {
                                                    data &&
                                                        <DeletedUserData
                                                            data={data}
                                                            key={i}
                                                        />
                                                }
                                            </>
                                        )})
                                    }
                                </TableBody>
                            }
                        </Table>
                        <Grid container justifyContent={'left'} sx={{padding:'16px 6px 32px 6px'}}>
                            {loading && 
                                <Box>
                                    <Stack ml={1} direction={"row"} gap={1} alignItems={'center'}>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                        <Skeleton variant="circular" animation="wave" height="30px" width="30px"/>
                                        <Skeleton animation="wave" height="30px" width="100px"/>
                                    </Stack>
                                </Box>
                            }
                            {!loading && deletedUserData.length > 0 &&
                                <Pagination
                                    color="secondary"
                                    count={totalPages}
                                    page={page}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    onChange={paginationHandle}
                                />
                            }
                        </Grid>

                        {deletedUserData.length <= 0  &&
                            <Grid container textAlign={'center'}>
                                <Grid item xs={12}>
                                    <Spacer space={8}/>
                                    <Typography variant='h4'> Deleted Users data not found </Typography>
                                    <Spacer space={11}/>
                                </Grid>
                            </Grid>
                        }
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
};

export default DeletedUsers;
