import {
	AccordionSummary,
	AccordionDetails,
	TableCell,
	Popper,
	autocompleteClasses,
	InputBase,
	IconButton,
	Button,
	Chip,
} from "@mui/material";
import { styled } from "@mui/system";

export const StyledAccordionSummary = styled(AccordionSummary)(
	({ theme, sx }) => ({
		padding: 0,
	})
);

export const StyledAccordionDetails = styled(AccordionDetails)(
	({ theme, sx }) => ({
		padding: "0px 0px 24px",
	})
);

export const StyledTableCell = styled(TableCell)(({ theme, sx }) => ({
	paddingTop: 16,
	paddingBottom: 16,
	paddingLeft: 24,
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
	border: `1px solid ${
		theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"
	}`,
	boxShadow: `0 8px 24px ${
		theme.palette.mode === "light"
			? "rgba(149, 157, 165, 0.2)"
			: "rgb(1, 4, 9)"
	}`,
	borderRadius: 4,
	width: 310,
	zIndex: theme.zIndex.modal,
	fontSize: 14,
	fontWeight: 500,
	color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
	backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
	'& .MuiAutocomplete-groupLabel' : {zIndex: "2"},
}));

export const StyledAutocompletePopper = styled("div")(({ theme }) => ({
	[`& .${autocompleteClasses.paper}`]: {
		boxShadow: "none",
		margin: 0,
		color: 'text.primary',
		fontSize: 14,
		fontWeight: 500,
	},
	[`& .${autocompleteClasses.listbox}`]: {
		backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
		padding: 0,
		[`& .${autocompleteClasses.option}`]: {
			minHeight: "auto",
			alignItems: "center",
			padding: "12",
		},
	},
	[`&.${autocompleteClasses.popperDisablePortal}`]: {
		position: "relative",
	},
}));

export const StyledAutocompleteInput = styled(InputBase)(({ theme }) => ({
	padding: 16,
	width: "100%",
	borderBottom: `1px solid ${
		theme.palette.mode === "light" ? "#eaecef" : "#30363d"
	}`,
	"& input": {
		borderRadius: 4,
		backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
		padding: 10,
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		border: `1px solid ${
			theme.palette.mode === "light" ? "#eaecef" : "#30363d"
		}`,
		fontSize: 14,
		"&:focus": {
			boxShadow: `0px 0px 0px 3px ${
				theme.palette.mode === "light"
					? "rgba(3, 102, 214, 0.3)"
					: "rgb(12, 45, 107)"
			}`,
			borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
		},
	},
}));

export const AutoCompleteIconButton = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	left: "260px",
	top: "50%",
	transform: "translateY(-50%)",
	color: "#57729E",
}));

export const AutocompleteCount = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: "47%",
	top: "50%",
	transform: "translateY(-50%)",
	color: "#57729E",
}));

export const AutoCompleteButton = styled(Button)(({ theme }) => ({
	padding: "12px",
	fontWeight: "400",
	borderRadius: "8px",
	color: "#57729E",
	justifyContent: "space-between",
	backgroundColor: "#121212",
	width:"330px",
	"&:hover": {
		backgroundColor: theme.palette.background.LightFilledInput,
		outline: "none",
	},
	// "&:focus": {
	// 	backgroundColor: theme.palette.background.LightFilledInput,
	// 	outline: "none",
	// },
}));

export const AutoCompleteTaxPlanBtn = styled(Button)(({ theme }) => ({
	paddingTop: "5px",
	paddingBottom: "5px",
	paddingLeft: "12px",
	paddingRight: "5px",
	fontWeight: "400",
	borderRadius: "8px",
	color: "#57729E",
	justifyContent: "space-between",
	backgroundColor: "#121212",
	width:"322px",
	"&:hover": {
		backgroundColor: theme.palette.background.LightFilledInput,
		outline: "none",
	}
}));

export const AutoCompleteViewTxnBtn = styled(Button)(({ theme }) => ({
	paddingTop: "5px",
	paddingBottom: "5px",
	paddingLeft: "12px",
	paddingRight: "5px",
	fontWeight: "400",
	borderRadius: "8px",
	color: "#57729E",
	justifyContent: "space-between",
	backgroundColor: "#121212",
	// width:"322px",
	"&:hover": {
		backgroundColor: theme.palette.background.LightFilledInput,
		outline: "none",
	}
}));

export const DialogCloseButton = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: 12,
	top: 13,
	// color: theme.palette.grey[500],
}));

export const ChipStyled = styled(Chip)(({ theme }) => ({
	fontSize:"13px",
	width:150, 
	borderRadius: 0,
	padding: 0,
}));
