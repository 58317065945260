import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'
import { DialogCloseButton } from '../../CustomDesignComponent'
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../_actions';
import CloseIcon from "@mui/icons-material/Close";
import Spacer from '../../Spacer/Spacer';
import { getBody } from '../../../../_services';
import { POST_DELETE_ACCOUNTANT_USER, POST_DELETE_USER_ACCOUNT } from '../../../../_services/users';

const DeleteUserAccount = (props) => {
    const openDeleteAccount = useSelector((state) => state.deleteUserAccountDialog);
    const userId = openDeleteAccount?.user_id ? openDeleteAccount?.user_id : null;
    const accountantId = openDeleteAccount?.accountant_id ? openDeleteAccount?.accountant_id : null;
	const dispatch = useDispatch();

    const handleAllUserTbl = () => {
		props.deleteAccount();
	}

    const handleAllAccountantTbl = () => {
		props.deleteAccountantUser();
	}

    const closeDeleteAccountDialog = () => {
		dispatch(
            userActions.deleteUserAccountDialog({
                isOpen: false,
                step: 1,
            })
		);
	}

    const submitDeleteAccount =() => {
        let data = new FormData()
        data.append("user_id", userId )

        POST_DELETE_USER_ACCOUNT(data)
        .then((res) => {
            res = getBody(res)
            if(res.code === 200){
            }
        })
        handleAllUserTbl();
        closeDeleteAccountDialog();
    }

    const submitAccountantDelete =() => {
        let data = new FormData()
        data.append("accountant_id", accountantId )

        POST_DELETE_ACCOUNTANT_USER(data)
        .then((res) => {
            res = getBody(res)
            if(res.code === 200){
            }
        })
        handleAllAccountantTbl();
        closeDeleteAccountDialog();
    }

    return (
        <Dialog
			open={openDeleteAccount.isOpen}
			onClose={closeDeleteAccountDialog}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>
                { openDeleteAccount?.user_id && " Delete User Account" }
                { openDeleteAccount?.accountant_id && " Delete Accountant User" }
				<DialogCloseButton
					aria-label="close"
					onClick={closeDeleteAccountDialog}
                    size="small"
				>
					<CloseIcon />
				</DialogCloseButton>
			</DialogTitle>
			<DialogContent dividers>
                <Grid container justifyContent={"center"} textAlign={'center'}>
                    <Grid item xs={12} sx={{ mb:4 }} >
                        <Spacer space={3}/>
                        <Typography variant='h4'> Sure To Delete Account ? </Typography>
                    </Grid>
                    { openDeleteAccount?.user_id &&
                        <Box textAlign= 'center'>
                            <Button onClick={closeDeleteAccountDialog} type="type" variant="text" sx={{ color: "#bdbdbd", mr:2 }} size="medium"> Close </Button>
                            <Button onClick={submitDeleteAccount} type="type" variant="text" color="error" size="medium"> Delete </Button>
                        </Box>
                    }
                    { openDeleteAccount?.accountant_id &&
                        <Box textAlign= 'center'>
                            <Button onClick={closeDeleteAccountDialog} type="type" variant="text" sx={{ color: "#bdbdbd", mr:2 }} size="medium"> Close </Button>
                            <Button onClick={submitAccountantDelete} type="type" variant="text" color="error" size="medium"> Delete </Button>
                        </Box>
                    }
                    <Spacer space={6}/>
                </Grid>
            </DialogContent>
		</Dialog>
    )
}

export default DeleteUserAccount