import { loginConstants } from "../_constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = (user ? {loggedIn:true} : {loggedIn:false});

export function login(state = initialState, action){
    const user = action.user ? action.user.user : undefined;
    switch(action.type){
        case loginConstants.LOGIN_REQUEST:
            return {
                user: user,
                loggedIn: true,
            }

        case loginConstants.USER_LOGOUT:
			return {
				user : null,
				loggedIn: false,
			}
        default:
            return state;
    }
}
