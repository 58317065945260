import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import RouteComponents from "./RouteComponents";
import {store} from "./_helpers"
import "./index.css";
import { BrowserRouter } from "react-router-dom";

// extend([mixPlugin]);

// WebFont.load({
// 	google: {
// 		families: ["Inter:100,200,300,400,500,600,700"],
// 	},
// });

// function pxToRem(value) {
// 	return `${value / 16}rem`;
// }
// const customColors = {
// 	primary: "#246AFF",
// 	secondary: "#9C27B0",
// 	error: "#D32F2F",
// 	warning: "#ED6C02",
// 	info: "#0288D1",
// 	success: "#2E7D32",
// };
// const colorPalette = {
// 	primary: {
// 		main: customColors.primary,
// 		dark: "#194AB2",
// 		light: "#4F87FF",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.primary).alpha(0.04).toHex(),
// 		"8p": colord(customColors.primary).alpha(0.08).toHex(),
// 		"12p": colord(customColors.primary).alpha(0.12).toHex(),
// 		"30p": colord(customColors.primary).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.primary).alpha(0.3).toHex(),
// 		"50p": colord(customColors.primary).alpha(0.5).toHex(),
// 	},
// 	lightprimary: {
// 		main: customColors.primary,
// 		dark: "#194AB2",
// 		light: "#4F87FF",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.primary).alpha(0.04).toHex(),
// 		"8p": colord(customColors.primary).alpha(0.08).toHex(),
// 		"12p": colord(customColors.primary).alpha(0.12).toHex(),
// 		"30p": colord(customColors.primary).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.primary).alpha(0.3).toHex(),
// 		"50p": colord(customColors.primary).alpha(0.5).toHex(),
// 	},
// 	secondary: {
// 		main: customColors.secondary,
// 		dark: "#194AB2",
// 		light: "#BA68C8",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.secondary).alpha(0.04).toHex(),
// 		"8p": colord(customColors.secondary).alpha(0.08).toHex(),
// 		"12p": colord(customColors.secondary).alpha(0.12).toHex(),
// 		"30p": colord(customColors.secondary).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.secondary).alpha(0.3).toHex(),
// 		"50p": colord(customColors.secondary).alpha(0.5).toHex(),
// 	},
// 	error: {
// 		main: customColors.error,
// 		dark: "#C62828",
// 		light: "#EF5350",
// 		contrastText: "#FFFFFF",
// 		red500: "#F44336",
// 		"4p": colord(customColors.error).alpha(0.04).toHex(),
// 		"8p": colord(customColors.error).alpha(0.08).toHex(),
// 		"12p": colord(customColors.error).alpha(0.12).toHex(),
// 		"30p": colord(customColors.error).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.error).alpha(0.3).toHex(),
// 		"50p": colord(customColors.error).alpha(0.5).toHex(),
// 		"160p": colord(customColors.error).mix("#000000", 0.6).toHex(),
// 		"190p": colord(customColors.error).alpha(0.9).toHex(),
// 	},
// 	lighterror: {
// 		main: customColors.error,
// 		dark: "#C62828",
// 		light: "#EF5350",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.error).alpha(0.04).toHex(),
// 		"8p": colord(customColors.error).alpha(0.08).toHex(),
// 		"12p": colord(customColors.error).alpha(0.12).toHex(),
// 		"30p": colord(customColors.error).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.error).alpha(0.3).toHex(),
// 		"50p": colord(customColors.error).alpha(0.5).toHex(),
// 		"160p": colord(customColors.error).mix("#000000", 0.6).toHex(),
// 		"190p": colord(customColors.error).alpha(0.9).toHex(),
// 	},
// 	warning: {
// 		main: customColors.warning,
// 		dark: "#E65100",
// 		light: "#FF9800",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.warning).alpha(0.04).toHex(),
// 		"8p": colord(customColors.warning).alpha(0.08).toHex(),
// 		"12p": colord(customColors.warning).alpha(0.12).toHex(),
// 		"30p": colord(customColors.warning).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.warning).alpha(0.3).toHex(),
// 		"50p": colord(customColors.warning).alpha(0.5).toHex(),
// 		"160p": colord(customColors.warning).mix("#000000", 0.6).toHex(),
// 		"190p": colord(customColors.warning).mix("#ffffff", 0.9).toHex(),
// 	},
// 	info: {
// 		main: customColors.info,
// 		dark: "#01579B",
// 		light: "#03A9F4",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.info).alpha(0.04).toHex(),
// 		"8p": colord(customColors.info).alpha(0.08).toHex(),
// 		"12p": colord(customColors.info).alpha(0.12).toHex(),
// 		"30p": colord(customColors.info).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.info).alpha(0.3).toHex(),
// 		"50p": colord(customColors.info).alpha(0.5).toHex(),
// 		"160p": colord(customColors.info).mix("#000000", 0.6).toHex(),
// 		"190p": colord(customColors.info).mix("#ffffff", 0.9).toHex(),
// 	},
// 	success: {
// 		main: customColors.success,
// 		dark: "#1B5E20",
// 		light: "#4CAF50",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.success).alpha(0.04).toHex(),
// 		"8p": colord(customColors.success).alpha(0.08).toHex(),
// 		"12p": colord(customColors.success).alpha(0.12).toHex(),
// 		"30p": colord(customColors.success).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.success).alpha(0.3).toHex(),
// 		"50p": colord(customColors.success).alpha(0.5).toHex(),
// 		"160p": colord(customColors.success).mix("#000000", 0.6).toHex(),
// 		"190p": colord(customColors.success).alpha(0.9).toHex(),
// 	},
// 	lightsuccess: {
// 		main: customColors.success,
// 		dark: "#1B5E20",
// 		light: "#4CAF50",
// 		contrastText: "#FFFFFF",
// 		"4p": colord(customColors.success).alpha(0.04).toHex(),
// 		"8p": colord(customColors.success).alpha(0.08).toHex(),
// 		"12p": colord(customColors.success).alpha(0.12).toHex(),
// 		"30p": colord(customColors.success).alpha(0.3).toHex(),
// 		"30pRipple": colord(customColors.success).alpha(0.3).toHex(),
// 		"50p": colord(customColors.success).alpha(0.5).toHex(),
// 		"160p": colord(customColors.success).mix("#000000", 0.6).toHex(),
// 		"190p": colord(customColors.success).alpha(0.9).toHex(),
// 	},
// 	cardOutLineLight: {
// 		main: colord("#000000").alpha(0.075).toHex(),
// 		light: colord("#000000").alpha(0.075).toHex(),
// 		dark: colord("#000000").alpha(0.075).toHex(),
// 	},
// 	lightBlueChip: {
// 		main: colord("#BBE2FF").alpha(0.75).toHex(),
// 		light: colord("#BBE2FF").alpha(0.75).toHex(),
// 		dark: colord("#BBE2FF").alpha(0.75).toHex(),
// 		hover: "#A9DAFF",
// 	},
// 	lightGreenChip: {
// 		main: "#E0FCE2",
// 		light: "#E0FCE2",
// 		dark: "#E0FCE2",
// 	},
// 	lightRedChip: {
// 		main: colord("#FCE0E0").toHex(),
// 		light: colord("#FCE0E0").toHex(),
// 		dark: colord("#FCE0E0").toHex(),
// 		hover: "#FFCDCD",
// 	},
// 	lightYellowChip: {
// 		main: colord("#FFFBD7").toHex(),
// 		light: colord("#FFFBD7").toHex(),
// 		dark: colord("#FFFBD7").toHex(),
// 	},
// 	lightGreenText: {
// 		main: colord("#35BA9B").toHex(),
// 		light: colord("#35BA9B").toHex(),
// 		dark: colord("#35BA9B").toHex(),
// 	},
// 	lightRedText: {
// 		main: colord("#FF2E2E").toHex(),
// 		light: colord("#FF2E2E").toHex(),
// 		dark: colord("#FF2E2E").toHex(),
// 	},
// 	lightYellowText: {
// 		main: colord("#8A6D3B").toHex(),
// 		light: colord("#8A6D3B").toHex(),
// 		dark: colord("#8A6D3B").toHex(),
// 	},
// 	toolTipBackground: {
// 		main: colord("#172B4D").toHex(),
// 		light: colord("#172B4D").toHex(),
// 		dark: colord("#172B4D").toHex(),
// 	},
// 	btnDisabled: {
// 		main: colord("#000000").alpha(0.26).toHex(),
// 		dark: colord("#000000").alpha(0.26).toHex(),
// 		light: colord("#000000").alpha(0.26).toHex(),
// 		contrastText: "#FFFFFF",
// 	},
// 	action: {
// 		active: colord("#434D5F").alpha(0.7).toHex(),
// 		active_54p: colord("#434D5F").alpha(0.54).toHex(),
// 		hover: colord("#000000").alpha(0.04).toHex(),
// 		selected: colord("#000000").alpha(0.08).toHex(),
// 		disabled: colord("#000000").alpha(0.26).toHex(),
// 		focus: colord("#000000").alpha(0.12).toHex(),
// 		disabledBackground: colord("#000000").alpha(0.12).toHex(),
// 		"30p": colord("#000000").alpha(0.03).toHex(),
// 		"30pRipple": colord("#000000").alpha(0.03).toHex(),
// 		"56p": colord("#FFFFFF").alpha(0.56).toHex(),
// 	},
// 	text: {
// 		primary: colord("#000000").alpha(0.87).toHex(),
// 		primary_4p: colord("#000000").alpha(0.04).toHex(),
// 		primary_30p: colord("#000000").alpha(0.3).toRgbString(),
// 		secondary: "#57729E",
// 		secondary_4p: colord("#57729E").alpha(0.04).toHex(),
// 		disabled: colord("#000000").alpha(0.38).toHex(),
// 	},
// 	divider: grey[200],
// 	background: {
// 		main: "#F3F5FC",
// 		default: "#f9fafc",
// 		LightFilledInput: "#F3F5FC",
// 		paper: "#FFFFFF",
// 		tableHeader:'#F0F4FC'
// 	},
// 	other: {
// 		Divider: colord("#57729E").alpha(0.8).toHex(),
// 		Divider50: colord("#57729E").alpha(0.5).toHex(),
// 	},
// 	CustomStyles: {
// 		Surface: colord("#8291A9").alpha(0.06).toHex(),
// 	},
// 	CardNewBorder: "#D1D5DB",
// };
// const breakpoints = {
// 	values: {
// 		xs: 0,
// 		TWsm: 400,
// 		sm: 600,
// 		card: 681,
// 		dialog:800,
// 		md: 900,
// 		md_desk:1080,
// 		lg: 1200,
// 		coinpanda: 1392,
// 		xl: 1536,
// 		fullwidth: 1920,
// 	},
// };

// const theme = createTheme({
// 	breakpoints: breakpoints,
// 	palette: colorPalette,
// 	components: {
// 		MuiButton: {
// 			styleOverrides: {
// 				root: {
// 					textTransform: "capitalize",
// 					padding: "8px 22px",
// 				},
// 			},
// 		},
// 		MuiTooltip: {
// 			styleOverrides: {
// 				tooltip: {
// 					backgroundColor: "#172B4D",
// 					fontSize: "10px",
// 					lineHeight: "14px",
// 					align: "left",
// 					verticalAlign: "center",
// 					color: "#FFFFFF",
// 					fill: "solid  #FFFFFF",
// 				},
// 				arrow: {
// 					color: "#172b4d",
// 				},
// 			},
// 		},
// 		MuiInput: {
// 			styleOverrides: {
// 				underline: {
// 					"&:after": {
// 						borderBottom: `2px solid #2867d3`,
// 					},
// 					"&$focused:after": {
// 						borderBottomColor: `1px solid #dcdfe4`,
// 					},
// 					"&$error:after": {
// 						borderBottomColor: `1px solid #dcdfe4`,
// 					},
// 					"&:before": {
// 						borderBottom: `1px solid #dcdfe4`,
// 					},
// 					"&:hover:not(.Mui-disabled):before": {
// 						borderBottom: `2px solid #246aff`,
// 					},
// 					"&$disabled:before": {
// 						borderBottom: `1px dotted #dcdfe4`,
// 					},
// 				},
// 			},
// 			variants: [
// 				{
// 					props: { variant: "contained" },
// 					style: {
// 						color: `#99a9c3`,
// 						"&:hover": {
// 							color: "#99a9c3",
// 						},
// 					},
// 				},
// 			],
// 		},
// 		MuiTextField: {
// 			styleOverrides: {
// 				root: {
// 					"& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
// 						{
// 							border: `2px solid #246aff`,
// 						},
// 					"& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
// 						{
// 							border: `2px solid #246aff`,
// 						},
// 				},
// 			},
// 		},
// 		MuiInputLabel: {
// 			styleOverrides: {
// 				standard: {
// 					color: `#ffffff`,
// 				},
// 			},
// 			variants: [
// 				{
// 					props: { variant: "standard" },
// 					style: {
// 						color: colorPalette.text.secondary,
// 						"&:focus": {
// 							color: colorPalette.text.secondary,
// 						},
// 					},
// 				},
// 			],
// 		},
// 		MuiIcon: {
// 			variants: [
// 				{
// 					props: { fontSize: "extraSmall" },
// 					style: {
// 						width: 8,
// 						height: 8,
// 					},
// 				},
// 			],
// 			styleOverrides: {
// 				standard: {
// 					color: `#ffffff`,
// 				},
// 			},
// 		},
// 		MuiDialog: {
// 			variants: [
// 				{
// 					props: { scroll: "paper" },
// 				},
// 			],
// 			styleOverrides: {
// 				root: {
// 					".MuiDialog-paperScrollPaper": {
// 						"*::-webkit-scrollbar": {
// 							width: "15px",
// 						},
// 						"*::-webkit-scrollbar-track": {
// 							background: "#F9F9F9",
// 						},
// 						"*::-webkit-scrollbar-thumb": {
// 							borderRadius: "8px",
// 							background: colorPalette.text.secondary,
// 							border: "4px solid #F9F9F9",
// 						},
// 					},
// 				},
// 				container: {
// 					alignItems: "flex-start",
// 				},
// 				paper: {
// 					borderRadius: 8,
// 				},
// 			},
// 		},
// 		MuiCard: {
// 			styleOverrides: {
// 				root: {
// 					border: "1px solid #E3E4E8",
// 					borderRadius: 8,
// 					paddingTop: 24,
// 				},
// 			},
// 			variants: [
// 				{
// 					props: { variant: "standard" },
// 					style: {
// 						color: grey[300],
// 						background: colorPalette.background.default,
// 						border: "0px",
// 					},
// 				},
// 			],
// 		},
// 		MuiCardHeader: {
// 			styleOverrides: {
// 				root: {
// 					padding: "0px 24px 24px 24px",
// 				},
// 				subheader: {
// 					color: colorPalette.text.secondary,
// 				},
// 			},
// 		},
// 		MuiCardContent: {
// 			styleOverrides: {
// 				root: {
// 					padding: "0px 24px 24px 24px",
// 					"*::-webkit-scrollbar": {
// 						width: "14px",
// 						boxShadow: "inset 1px 0 0 #DEDEDE",
// 					},
// 					"*::-webkit-scrollbar-track": {
// 						background: "#F9F9F9",
// 					},
// 					"*::-webkit-scrollbar-thumb": {
// 						borderRadius: "8px",
// 						background: colorPalette.text.secondary,
// 						border: "4px solid #F9F9F9",
// 					},
// 				},
// 			},
// 		},
// 		MuiDivider: {
// 			styleOverrides: {
// 				root: {
// 					color: "rgba(0, 0, 0, 0.3)",
// 					borderColor: "#E3E4E8",
// 					borderBottomWidth: "1px",
// 				},
// 			},
// 			variants: [
// 				{
// 					props: { variant: "cardHeaderDivider" },
// 					style: {
// 						marginLeft: -24,
// 						marginRight: -24,
// 					},
// 				},
// 			],
// 		},
// 		MuiSnackbar: {
// 			variants: [
// 				{
// 					props: { variant: "primary" },
// 					style: {
// 						"& .MuiSnackbarContent-root": {
// 							background: colorPalette.primary.main,
// 						},
// 					},
// 				},
// 				{
// 					props: { variant: "success" },
// 					style: {
// 						"& .MuiSnackbarContent-root": {
// 							background: colorPalette.lightGreenText.main,
// 						},
// 					},
// 				},
// 				{
// 					props: { variant: "error" },
// 					style: {
// 						"& .MuiSnackbarContent-root": {
// 							background: colorPalette.error.main,
// 						},
// 					},
// 				},
// 			],
// 		},
// 		MuiBadge: {
// 			styleOverrides: {
// 				root: {
// 					width: "100%",
// 				},
// 				standard: {
// 					borderRadius: "4px",
// 					paddingTop: 12,
// 					paddingBottom: 12,
// 				},
// 			},
// 		},
// 		MuiChip: {
// 			variants: [
// 				{
// 					props: { color: "success" },
// 					style: {
// 						background: colorPalette.lightGreenChip.main,
// 						color: colorPalette.success.dark,
// 					},
// 				},
// 				{
// 					props: { color: "secondary" },
// 					style: {
// 						background: cyan[700],
// 						color: colorPalette.secondary.contrastText,
// 					},
// 				},
// 				{
// 					props: { color: "error" },
// 					style: {
// 						background: colorPalette.lightRedChip.main,
// 						color: colorPalette.error.dark,
// 					},
// 				},
// 				{
// 					props: { color: "info" },
// 					style: {
// 						background: colorPalette.lightBlueChip.main,
// 						color: colorPalette.primary.dark,
// 					},
// 				},
// 				{
// 					props: { color: "warning" },
// 					style: {
// 						background: colorPalette.lightYellowChip.light,
// 						color: colorPalette.lightYellowText.main,
// 					},
// 				},
// 				{
// 					props: { color: "lightprimary" },
// 					style: {
// 						background: colorPalette.lightBlueChip.light,
// 						color: colorPalette.primary.dark,
// 					},
// 				},
// 				{
// 					props: { color: "lightsuccess" },
// 					style: {
// 						background: colorPalette.lightGreenChip.main,
// 						color: colorPalette.success.dark,
// 					},
// 				},
// 				{
// 					props: { color: "lighterror" },
// 					style: {
// 						background: colorPalette.lightRedChip.light,
// 						color: colorPalette.error.dark,
// 					},
// 				},
// 			],
// 			styleOverrides: {
// 				root: {
// 					borderRadius: 8,
// 					padding: 6,
// 				},
// 			},
// 		},
// 		MuiAccordion: {
// 			variants: [
// 				{
// 					props: { variant: "border" },
// 					style: {
// 						"&:before": {
// 							top: 0,
// 						},
// 						"&.Mui-expanded:before": {
// 							opacity: 1,
// 							display: "block !important",
// 						},
// 						"&.Mui-expanded:first-of-type:before": {
// 							display: "none !important",
// 						},
// 					},
// 				},
// 				{
// 					props: { variant: "borderRadius" },
// 					style: {
// 						borderRadius: "8px !important",
// 					},
// 				},
// 			],
// 			styleOverrides: {
// 				root: {
// 					"&.Mui-disabled": {
// 						backgroundColor: "#FFFFFF",
// 					},
// 				},
// 			},
// 		},
// 		MuiTableHead: {
// 			styleOverrides: {
// 				root: {
// 					backgroundColor: colorPalette.primary["4p"],
// 					color: colorPalette.text.secondary,
// 				},
// 			},
// 		},
// 		MuiTableCell: {
// 			variants: [
// 				{
// 					props: { variant: "head" },
// 					style: {
// 						color: colorPalette.text.secondary,
// 						textTransform: "upperCase",
// 						padding: "8px 24px",
// 						background: 'none',
// 					},
// 				},
// 			],
// 			styleOverrides: {
// 				root: {
// 					borderBottom: `0.5px solid #D1D5DB`,
// 				},
// 			},
// 		},
// 		MuiTableRow: {
// 			styleOverrides: {
// 				root: {
// 					hover: {
// 						backgroundColor: colord("#000000").alpha(0.04).toHex(),
// 					},
// 				},
// 			},
// 		},
// 		MuiDialogTitle: {
// 			styleOverrides: {
// 				root: {
// 					padding: 24,
// 				},
// 			},
// 		},
// 		MuiDialogContent: {
// 			styleOverrides: {
// 				root: {
// 					padding: 24,
// 				},
// 			},
// 		},
// 		MuiPaper: {
// 			variants: [
// 				{
// 					props: { variant: "stats" },
// 					style: {
// 						backgroundColor: colorPalette.primary["4p"],
// 					},
// 				},
// 				{
// 					props: { variant: "invite" },
// 					style: {
// 						backgroundColor: colorPalette.primary["8p"],
// 					},
// 				},
// 			],
// 		},
// 		MuiCardActions: {
// 			styleOverrides: {
// 				root: {
// 					"&:hover": {
// 						backgroundColor: "#FFFFFF",
// 					},
// 				},
// 			},
// 		},
// 		MuiAlert: {
// 			variants: [
// 				{
// 					props: { severity: "warning" },
// 					style: {
// 						background: colorPalette.warning["190p"],
// 						color: colorPalette.warning["160p"],
// 					},
// 				},
// 			],
// 		},
// 		MuiAutocomplete: {
// 			styleOverrides: {
// 				paper: {
// 					"*::-webkit-scrollbar": {
// 						width: "14px",
// 						boxShadow: "inset 1px 0 0 #DEDEDE",
// 					},
// 					"*::-webkit-scrollbar-track": {
// 						background: "#F9F9F9",
// 					},
// 					"*::-webkit-scrollbar-thumb": {
// 						borderRadius: "8px",
// 						background: colorPalette.text.secondary,
// 						border: "4px solid #F9F9F9",
// 					},
// 				},
// 			},
// 		},
// 		MuiMenu: {
// 			styleOverrides: {
// 				root: {
// 					"*::-webkit-scrollbar": {
// 						width: "14px",
// 						boxShadow: "inset 1px 0 0 #DEDEDE",
// 					},
// 					"*::-webkit-scrollbar-track": {
// 						background: "#F9F9F9",
// 					},
// 					"*::-webkit-scrollbar-thumb": {
// 						borderRadius: "8px",
// 						background: colorPalette.text.secondary,
// 						border: "4px solid #F9F9F9",
// 					},
// 				},
// 			},
// 		},
// 	},
// 	typography: {
// 		fontFamily: "Inter",
// 		h1: {
// 			fontWeight: 700,
// 			fontSize: pxToRem(96),
// 			letterSpacing: "-1.5px",
// 		},
// 		h2: {
// 			fontWeight: 700,
// 			fontSize: pxToRem(60),
// 			[`@media screen and (min-width: ${breakpoints.values.xs}px) and (max-width: ${breakpoints.values.sm}px)`]:
// 				{
// 					fontSize: pxToRem(40),
// 				},
// 			letterSpacing: "-0.5px",
// 		},
// 		h3: {
// 			fontWeight: 700,
// 			fontSize: pxToRem(48),
// 			[`@media screen and (min-width: ${breakpoints.values.xs}px) and (max-width: ${breakpoints.values.sm}px)`]:
// 				{
// 					fontSize: pxToRem(30),
// 				},
// 			letterSpacing: "0px",
// 		},
// 		h4: {
// 			fontWeight: 700,
// 			fontSize: pxToRem(34),
// 			[`@media screen and (min-width: ${breakpoints.values.xs}px) and (max-width: ${breakpoints.values.sm}px)`]:
// 				{
// 					fontSize: pxToRem(24),
// 				},
// 			letterSpacing: "0.25px",
// 		},
// 		h5: {
// 			fontWeight: 600,
// 			fontSize: pxToRem(26),
// 			[`@media screen and (min-width: ${breakpoints.values.xs}px) and (max-width: ${breakpoints.values.sm}px)`]:
// 				{
// 					fontSize: pxToRem(18),
// 				},
// 			letterSpacing: "0px",
// 		},
// 		h6: {
// 			fontWeight: 600,
// 			fontSize: pxToRem(18),
// 			[`@media screen and (min-width: ${breakpoints.values.xs}px) and (max-width: ${breakpoints.values.sm}px)`]:
// 				{
// 					fontSize: pxToRem(14),
// 				},
// 			letterSpacing: "0.15px",
// 		},
// 		subtitle1: {
// 			fontWeight: 600,
// 			fontSize: pxToRem(16),
// 			letterSpacing: "0.15px",
// 		},
// 		subtitle2: {
// 			fontWeight: 500,
// 			fontSize: pxToRem(14),
// 			letterSpacing: "0.1px",
// 		},
// 		body1: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(16),
// 			letterSpacing: "0.15px",
// 		},
// 		body2: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(14),
// 			letterSpacing: "0.15px",
// 		},
// 		buttonlarge: {
// 			fontWeight: 500,
// 			fontSize: pxToRem(15),
// 			letterSpacing: "0.46px",
// 		},
// 		buttonmedium: {
// 			fontWeight: 500,
// 			fontSize: pxToRem(14),
// 			letterSpacing: "0.4px",
// 		},
// 		buttonsmall: {
// 			fontWeight: 500,
// 			fontSize: pxToRem(14),
// 			letterSpacing: "0.46",
// 		},
// 		caption: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(12),
// 			letterSpacing: "0.4px",
// 		},
// 		overline: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(12),
// 			textTransform: "uppercase",
// 			letterSpacing: "1px",
// 		},
// 		avatarLetter: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(20),
// 			letterSpacing: "0.14px",
// 		},
// 		inputLabel: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(12),
// 			letterSpacing: "0.15px",
// 		},
// 		helperText: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(12),
// 			letterSpacing: "0.4px",
// 		},
// 		inputText: {
// 			fontWeight: 400,
// 			fontSize: pxToRem(14),
// 			letterSpacing: "0.15px",
// 		},
// 		toolTip: {
// 			fontWeight: 500,
// 			fontSize: pxToRem(10),
// 			letterSpacing: "0px",
// 		},
// 		linkText: {
// 			fontWeight: 500,
// 			fontSize: pxToRem(13),
// 			lineHeight: "22px",
// 			letterSpacing: "0.46px",
// 		},
// 		TWh5: {
// 			fontWeight: 600,
// 			fontSize: pxToRem(26),
// 			letterSpacing: "0px",
// 		},
// 		planh2: {
// 			fontWeight: 700,
// 			fontSize: '60px',
// 		},
// 		planh3: {
// 			fontWeight: 700,
// 			fontSize: '48px',
// 		},
// 		planh6: {
// 			fontWeight: 600,
// 			fontSize: '18px',
// 		},		
// 	},
// 	shape: {
// 		// borderRadius: 8,
// 	},
// });

const root = createRoot(document.getElementById("root"));
root.render(
		<Provider store={store}>
			<React.StrictMode>
				<BrowserRouter>
					<RouteComponents />
				</BrowserRouter>
			</React.StrictMode>
		</Provider>,
);
