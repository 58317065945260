const API_URL = process.env.REACT_APP_API_URL;

let addPrefix = (route) => {
	return API_URL + "/" + route;
};

export const ROUTE_LOGIN = addPrefix("admin/login");

// Users menus

export const ROUTE_ALL_USERS = addPrefix("admin/users/list");
export const ROUTE_ALL_ACCOUNTANTS = addPrefix("admin/accountant/list");
export const ROUTE_ADMIN_USERS = addPrefix("admin/list");
export const ROUTE_UNVERIFIED_USERS = addPrefix("admin/users/unverified");
export const ROUTE_TAX_REPORTS = addPrefix("admin/users/tax-reports");
export const ROUTE_DELETED_USERS = addPrefix("admin/users/deleted");
export const ROUTE_PENDING_DELETED_USERS = addPrefix("admin/users/pending-deleted");
export const ROUTE_PARTNER_USERS = addPrefix("admin/partner-user/list");
export const ROUTE_USER_DETAILS = addPrefix("admin/user/detail");
export const ROUTE_USER_DETAILS_UPDATE = addPrefix("admin/user/detail-update");
export const ROUTE_UPDATE_BLOCKCHAIN_LIMIT = addPrefix("admin/user/update-blockchain-limit");
export const ROUTE_UPDATE_USER_TAX_PLAN = addPrefix("admin/user/update-plan");
export const ROUTE_VIEW_USER_TRANSACTION = addPrefix("admin/user/transactions");
export const ROUTE_USER_LOGIN = addPrefix("admin/user/login");
export const ROUTE_DOWNLOAD_TAXREPORT = addPrefix("admin/user/report/create");
export const ROUTE_USER_REPORT_LIST = addPrefix("admin/user/report/list");
export const ROUTE_DOWNLOAD_USER_REPORT = addPrefix("admin/user/report/download");
export const ROUTE_DELETE_USER_ACCOUNT = addPrefix("admin/user/delete");
export const ROUTE_ACCOUNT_LOGIN = addPrefix("admin/accountant/login");
export const ROUTE_DELETE_ACCOUNTANT_USER = addPrefix("admin/accountant/delete");
export const ROUTE_EDIT_ADMIN_USER = addPrefix("admin/update-password");