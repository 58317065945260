import { Box, Button, Card, CardHeader, Checkbox, Container, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import React from 'react';
import Logo from "../../../assets/images/coinpandaLogo.svg";
import image from "../../../assets/images/loginBgimg.jpg";
import Spacer from '../Spacer/Spacer';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { REQUEST_LOGIN } from '../../../_services/auth';
import { getBody } from '../../../_services';
import { loginActions } from '../../../_actions';

const loginSchema = yup.object().shape({
  email: yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string()
    .required("Password is a required")
    .min(5, "Password must be at least 5 characters")
    .max(20, "Password must not exceed 20 characters"),
})
const Login = () => {

  const dispatch = useDispatch();
  let history = useNavigate();
  // const handleSubmit = (value,actions ) => {
  //   localStorage.setItem("user", JSON.stringify(value));
  //   dispatch(userActions.login(value));
  //   history("/dashboard");
  //   actions.resetForm();
  // }

  const handleSubmit = ({ email, password },{ setFieldError, setSubmitting }) => {
    setSubmitting(true);
		let data = new FormData();
		data.append("email", email);
		data.append("password", password);

    REQUEST_LOGIN(data)
      .then((res) => {
        setSubmitting(false);
        res = getBody(res);
        if (res.code === 200) {
          const user = res.data;
          const accessToken = res.data.access_token;
          localStorage.setItem("user", JSON.stringify(user));
					localStorage.setItem("access_token", accessToken);
          dispatch(loginActions.login(user, accessToken));
          history("/dashboard");
        }
      })
  }

  React.useEffect(() => {
    if(localStorage.getItem("user") && localStorage.getItem("access_token")){
      history("/dashboard");
    }
    else{
      history("/login");
    }
  }, [])

  return (
    <div style={{ backgroundImage: `url(${image})`, width:"100vw", height:"200vh"}} >
      <Container>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item textAlign={'center'} md={4.6} mt={3}>
            <Card variant={"outlined"} sx={{ padding:2 ,backgroundColor: "rgba(0,0,0,0.2)", border:"none" }}>
              <CardHeader 
                title={
                    <>
                      <Spacer space={1} />
                      <Box>
                        <img 
                          src={Logo}
                          alt="text"
                          width="100%"
                          height={"75px"}
                        />
                      </Box>
                    </>
                  }
              >
              </CardHeader>
              <Typography variant='body1' sx={{ opacity:0.4 , color:"#ffffff" }} >Sign In To Admin</Typography>
              <Spacer space={1} />
              <Typography variant='body2' sx={{ opacity:0.4 , color:"#ffffff" }} >Enter your details to login to your account:</Typography>
              <Spacer space={7} />
              <Formik
                initialValues={{
									email: "",
									password: "",
								}}
								enableReinitialize={true}
                // validationSchema={loginSchema}
                validateOnChange={true}
                onSubmit={( values, actions ) => {
                  handleSubmit(values, actions);                  
                }}
              >
                {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, isValid }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextField onBlur={handleBlur} onChange={handleChange} fullWidth sx={{borderRadius:12.5}} autoComplete="off" variant="standard" name="email" label={"Email"} size="small" required></TextField>
                    <Spacer space={3} />
                    
                    <TextField onBlur={handleBlur} onChange={handleChange} fullWidth sx={{borderRadius:12.5 }} autoComplete="off" variant="standard" type="password" name="password" label={"Password"} size="small" required></TextField>
                    <Spacer space={2} />
                    
                    <Grid container alignItems={"center"}>
                      <FormControlLabel
                        control={
                          <Checkbox aria-label="checkbox" name="terms" label="Terms" />
                        }
                        sx={{mr : "0",}}
                      >
                      </FormControlLabel>
                      <Typography variant={'caption'} sx={{ color: 'text.secondary'}}>Remember me</Typography>
                    </Grid>
                    <Spacer space={6} />
                    <Button variant="contained" size="large" type="submit" sx={{ borderRadius:12.5}} >Sign In</Button>
                    <Spacer space={2} />
                  </Form>
                )}
              </Formik>
            </Card>
          
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default Login